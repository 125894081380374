import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import logo from "../../../img/fbas-logo.svg";
import SpinnerButton from "../../widget/SpinnerButton";
import { StoreContext } from "../../../store/StoreContext";
import { InputText } from "../../helpers/FormInputs";
import { fetchData } from "../../helpers/fetchData";
import { getUrlParam } from "../../helpers/general-functions";
import ModalSuccess from "../../widget/ModalSuccess";
import ModalError from "../../widget/ModalError";
import Spinner from "../../widget/Spinner";
import useLoadAll from "../../custom-hooks/useLoadAll";
import { setCreatePass } from "../../../store/StoreAction";

const LoginForgotPasswordNew = () => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [btnLoading, setLoading] = React.useState(false);
  const { loading, result } = useLoadAll(
    "/admin/users/read-users-key.php",
    getUrlParam().get("key")
  );

  const initVal = {
    users_pass: "",
    users_key: getUrlParam().get("key"),
  };

  const yupSchema = Yup.object({
    users_pass: Yup.string().required("Required"),
  });

  if (getUrlParam().get("key") === null || getUrlParam().get("key") === "") {
    return <p>The page you're trying to view has expired or invalid.</p>;
  }

  return (
    <>
      {store.success && <ModalSuccess />}
      {/* {store.error && <ModalError />} */}
      {loading ? (
        <Spinner />
      ) : result.length > 0 ? (
        <main className="main__login">
          <div className="main__login__wrapper">
            <div className="main__login__form flex-basis-75">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h1 className="color-primary t-bold t-center">
                    Create Password
                  </h1>
                  <p className="t-center mb--60">Enter a password.</p>
                  <Formik
                    initialValues={initVal}
                    validationSchema={yupSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      console.log(values);
                      fetchData(
                        setLoading,
                        "/admin/users/update-users-new-pass.php",
                        values, // form data values
                        null, // result set data
                        "Password successfuly created.", // success msg
                        "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
                        dispatch, // context api action
                        store, // context api state
                        true, // boolean to show success modal
                        false // boolean to show load more functionality button
                      );
                      dispatch(setCreatePass(true));
                    }}
                  >
                    {(props) => {
                      // console.log(props.isSubmitting);
                      // if (!isAccount) {
                      //   props.values.users_email = "";
                      // }
                      return (
                        <Form>
                          <div className="content-box mb--30">
                            <InputText
                              label="Password"
                              type="password"
                              name="users_pass"
                              disabled={btnLoading}
                            />
                          </div>

                          {/* <a href="/" className="btn btn-full">
                    SUBMIT
                  </a> */}
                          <div className="button-container">
                            <button
                              type="submit"
                              className="btn btn-full"
                              disabled={btnLoading}
                            >
                              CREATE {btnLoading && <SpinnerButton />}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
              </div>
            </div>

            <div className="main__login__banner lg-only t-center  flex-basis-25">
              <div className="welcome__note">
                <img src={logo} alt="" width="150" />
                <h3 className="mb--40 color-lightcolor">
                  Financial Blessings And Support Association Philippines
                </h3>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <p>The page you're trying to view has expired or invalid.</p>
      )}
    </>
  );
};

export default LoginForgotPasswordNew;
