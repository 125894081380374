import React from "react";
import ModalError from "../../widget/ModalError";
import { StoreContext } from "../../../store/StoreContext";
import useFetchDataLoadMore from "../../custom-hooks/useFetchDataLoadMore";
import MembersSearch from "../members/MembersSearch";
import MembersSearchTableRows from "../members/MembersSearchTableRows";
import LoadMore from "../../widget/LoadMore";
import CashierMembersTableRows from "./CashierMembersTableRows";
import CashierSearchTableRows from "./CashierSearchTableRows";

const CashierMembers = ({ search }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const {
    loading,
    handleLoad,
    // result,
    totalResult,
    handleSearch,
    // isSearch,
    handleChange,
  } = useFetchDataLoadMore(
    "/admin/members/read-members.php",
    "/admin/members/read-members-all.php",
    25, // per page to show
    null,
    "/admin/members/read-members-search.php"
  );

  return (
    <>
      {store.error && <ModalError />}

      <div className="search-bar t-center mb--20">
        <MembersSearch
          handleSearch={handleSearch}
          handleChange={handleChange}
          store={store}
          search={search}
          loading={loading}
        />
      </div>
      <div className="list mb--20 responsive-table">
        <table id="" className="" cellSpacing="0" width="100%">
          <thead className="title-box-light">
            <tr>
              <th className="">#</th>
              <th className="">Names</th>
              <th className="">CID</th>
              {/* <th className="desktop-view">Address</th> */}
              <th className="desktop-view">Created</th>
              {/* <th className="desktop-view">Updated</th> */}
              <th className="t-right">Actions</th>
            </tr>
          </thead>
          <tbody id="directory">
            {store.isSearch ? (
              <CashierSearchTableRows
                store={store}
                loading={loading}
                dispatch={dispatch}
              />
            ) : (
              <CashierMembersTableRows
                dispatch={dispatch}
                store={store}
                loading={loading}
              />
            )}
          </tbody>
        </table>
      </div>
      {!store.isSearch && (
        <LoadMore
          handleLoad={handleLoad}
          loading={loading}
          // result={result}
          store={store}
          totalResult={totalResult}
        />
      )}
    </>
  );
};

export default CashierMembers;
