import React from "react";

const Page404 = (props) => {
  // const [toggle, setToggle] = React.useState(false);
  return (
    <>
      <div className="main-content">
        <div className="page-not-found">
          <div className="container">
            <div className="page-not-found__content">
              <h1>
                <span>404</span>
                Page not found.
              </h1>
              <p>We're sorry, the page you were looking for cannot be found.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
