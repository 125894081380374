import React from "react";
import Navigation from "../../../navigation/Navigation";
import Header from "../../../header/Header";
import Footer from "../../../footer/Footer";
import { FaCaretDown, FaCog, FaUserPlus } from "react-icons/fa";
import useLoadAll from "../../../custom-hooks/useLoadAll";
import Spinner from "../../../widget/Spinner";
import NoData from "../../../widget/NoData";
import { StoreContext } from "../../../../store/StoreContext";
import {
  setIsAdd,
  setIsConfirm,
  setMessage,
} from "../../../../store/StoreAction";
import ModalAddADPProgram from "./ModalAddADPProgram";
import { numberWithCommas } from "../../../helpers/general-functions";
import { Link } from "react-router-dom";
import ModalSuccess from "../../../widget/ModalSuccess";
import ModalError from "../../../widget/ModalError";
import ModalYesNo from "../../../widget/ModalYesNo";

const SettingsProgramList = () => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [toggle, setToggle] = React.useState(false);
  const [itemEdit, setItemEdit] = React.useState(false);
  const { loading, result } = useLoadAll(
    "/admin/program-list/read-programlist-all.php"
  );
  let count = 0;

  const handleAdd = () => {
    dispatch(setIsAdd(true));
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    dispatch(setIsAdd(true));
    setItemEdit(item);
  };

  const handleDelete = (e, item) => {
    e.preventDefault();
    setItemEdit(item.programlist_aid);
    dispatch(setIsConfirm(true));
    dispatch(setMessage("Are you sure to delete?"));
  };

  return (
    <>
      {/* <ModalAddADPProgram /> */}
      {store.isAdd && (
        <ModalAddADPProgram
          edit={{ itemEdit, setItemEdit }}
          num={result.length}
        />
      )}
      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}
      {store.isConfirm && (
        <ModalYesNo
          edit={{ itemEdit, setItemEdit }}
          url="/admin/program-list/delete-programlist.php"
        />
      )}
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="settings" />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">ADP PROGRAMS</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                <button
                  className="btn right"
                  id="create-new"
                  onClick={handleAdd}
                >
                  <span>
                    <FaUserPlus />
                  </span>
                  <span>Create</span>
                </button>
              </div>
            </div>
          </div>

          <hr />

          <div className="member content-block clearfix mb--20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="content-box bg-highlight bg-lightcolor mb--20">
                  <div className="list mb--40 responsive-table">
                    <table id="" className="" cellSpacing="0" width="100%">
                      <thead className="title-box-light">
                        <tr>
                          <th className="">#</th>
                          <th className="">Program Name</th>
                          <th className="">No. of Months</th>
                          <th className="">Monthly Installment</th>
                          <th className="">Hospitalization Benefits</th>
                          <th className="">Medical Benefits</th>
                          <th className="">Total Membership</th>
                          <th className="t-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result.length > 0 ? (
                          result.map((item) => {
                            count += 1;
                            return (
                              <tr key={item.programlist_aid}>
                                <td className="">{count}</td>
                                <td className="">{item.programlist_name}</td>
                                <td className="">{item.programlist_months}</td>
                                <td className="">
                                  {item.programlist_inst_amount}
                                </td>
                                <td className="">
                                  {numberWithCommas(
                                    item.programlist_hospital_amount
                                  )}
                                </td>
                                <td className="">
                                  {numberWithCommas(
                                    item.programlist_medical_amount
                                  )}
                                </td>
                                <td className="">
                                  {numberWithCommas(
                                    item.programlist_member_amount
                                  )}
                                </td>
                                <td className="t-right">
                                  <div className="dropdown">
                                    <span>
                                      <FaCog />
                                      <FaCaretDown />
                                    </span>
                                    <div className="dropdown-content">
                                      <p>
                                        <Link
                                          to={`/admin/settings-program-${item.programlist_inst_amount}`}
                                        >
                                          Service Value
                                        </Link>
                                      </p>
                                      <p>
                                        <Link
                                          to="/settings"
                                          onClick={(e) => handleEdit(e, item)}
                                        >
                                          Edit
                                        </Link>
                                      </p>
                                      <p>
                                        <Link
                                          to="/settings"
                                          onClick={(e) => handleDelete(e, item)}
                                        >
                                          Delete
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="8" style={{ height: "100px" }}>
                              {loading && <Spinner />}
                              <NoData />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SettingsProgramList;
