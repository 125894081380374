import React from "react";
import Navigation from "../../navigation/Navigation";
import Header from "../../header/Header";
import { FaCaretDown, FaCog, FaUserPlus } from "react-icons/fa";
import Footer from "../../footer/Footer";

const MembersViewLedgerMedicalRemarks = () => {
  const [toggle, setToggle] = React.useState(false);
  return (
    <>
      <Header tog={{ toggle, setToggle }} />
      <Navigation />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">
                  MEDICAL REIMBURSEMENT DETAILS{" "}
                </span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                <button className="btn right" id="create-new">
                  <span>
                    <FaUserPlus />
                  </span>
                  <span>Create</span>
                </button>
              </div>
            </div>
          </div>

          <hr />

          <div className="member content-block clearfix mb--20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="content-box bg-highlight bg-lightcolor mb--20">
                  <div className="list responsive-table">
                    <table id="" className="" cellSpacing="0" width="100%">
                      <thead className="title-box-light">
                        <tr>
                          <th className="" width="150">
                            Date
                          </th>
                          <th className="t-center" width="150">
                            Amount
                          </th>
                          <th className="">Remarks</th>
                          <th className="t-center" width="150">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody id="directory">
                        <tr>
                          <td className="">Oct 13 2021</td>
                          <td className="t-center">2000</td>
                          <td className="">
                            This is a sample remarks for initial assistance of
                            cash benefits. This is a sample remarks for initial
                            assistance of cash benefits. This is a sample
                            remarks for initial assistance of cash benefits
                          </td>
                          <td className="t-center">
                            <div className="dropdown">
                              <span>
                                <FaCog />
                                <FaCaretDown />
                              </span>
                              <div className="dropdown-content">
                                <p>
                                  <a href="/"> Edit </a>
                                </p>
                                <p>
                                  <a href="/"> Delete </a>
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="">Oct 13 2021</td>
                          <td className="t-center">2000</td>
                          <td className="">
                            This is a sample remarks for initial assistance of
                            cash benefits. This is a sample remarks for initial
                            assistance of cash benefits. This is a sample
                            remarks for initial assistance of cash benefits
                          </td>
                          <td className="t-center">
                            <div className="dropdown">
                              <span>
                                <FaCog />
                                <FaCaretDown />
                              </span>
                              <div className="dropdown-content">
                                <p>
                                  <a href="/"> Edit </a>
                                </p>
                                <p>
                                  <a href="/"> Delete </a>
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MembersViewLedgerMedicalRemarks;
