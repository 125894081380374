import fetchApi from "./fetchApi";
import { devApiUrl, doAllList, doLoadmoreList } from "./general-functions";
import {
  setSuccess,
  setMessage,
  setError,
  setResultSet,
  setSave,
  setIsConfirm,
  setIsAdd,
} from "../../store/StoreAction";

export const fetchCUD = async (
  setLoading = null,
  endpoint,
  fd,
  successMsg = "",
  errorMsg = "",
  dispatch,
  store,
  successModal,
  isLoadMore
) => {
  // // reset to empty the value of result set
  // dispatch(setResultSet([]));
  setLoading !== null && setLoading(true);
  const data = await fetchApi(devApiUrl + endpoint, fd);

  // console.log(data);

  // always fill the context result with fetch data
  isLoadMore
    ? doLoadmoreList(store, dispatch, data)
    : doAllList(dispatch, data);

  // if (data.data === null || !data.status) {
  //   dispatch(setResultSet([]));
  // } else {
  //   dispatch(setResultSet(data.data));
  // }

  if (typeof data === "undefined") {
    console.log("undefined");
    setLoading !== null && setLoading(false);
  }

  if (!data.status) {
    console.log(data.message);
    setLoading !== null && setLoading(false);
    // add modal will be closed when used
    if (store.isAdd) {
      dispatch(setIsAdd(false));
    }
    dispatch(setError(true));
    dispatch(setMessage(errorMsg));
  }

  if (data.status) {
    // console.log("Fetch success");
    setLoading !== null && setLoading(false);

    // add modal will be closed when used
    if (store.isAdd) {
      dispatch(setIsAdd(false));
      store.isSave ? dispatch(setSave(false)) : dispatch(setSave(true));
    }

    // delete modal will be closed when used
    if (store.isConfirm) {
      dispatch(setIsConfirm(false));
      store.isSave ? dispatch(setSave(false)) : dispatch(setSave(true));
    }

    // success modal will be closed when used
    if (successModal) {
      dispatch(setSuccess(true));
      dispatch(setMessage(successMsg));
    }

    // // redirect to other pager
    // if (isPush) {
    //   dispatch(setSuccess(true));
    //   dispatch(setMessage(successMsg));
    // }
  }
};
