import React from "react";
import { AiFillPropertySafety } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";

import logo from "../../../img/fbas-logo.svg";

const LoginSignupUserType = (props) => {
  return (
    <>
      <main className="main__login">
        <div className="main__login__wrapper">
          <div className="main__login__form flex-basis-75">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h1 className="color-primary t-bold t-center">
                  Choose User Type
                </h1>
                <p className="t-center mb--60">
                  Choose whether you are a regular member or coordinator.
                </p>

                <form>
                  <div className="button-container">
                    <button
                      className="btn btn-full"
                      onClick={() => props.history.push("/member-signup")}
                    >
                      <h1>
                        <FaUser />
                      </h1>
                      <span>MEMBER</span>
                    </button>
                    {/* &nbsp;&nbsp; */}
                    <button
                      className="btn btn-full"
                      onClick={() => props.history.push("/coordinator-signup")}
                    >
                      <h1>
                        <FaUserTie />
                      </h1>
                      <span>COORDINATOR</span>
                    </button>
                  </div>

                  <a className="go_back" href="/login">
                    Already a member? Login here.
                  </a>
                </form>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
            </div>
          </div>

          <div className="main__login__banner lg-only t-center  flex-basis-25">
            <div className="welcome__note">
              <img src={logo} alt="" width="150" />
              <h3 className="mb--40 color-lightcolor">
                Financial Blessings And Support Association Philippines
              </h3>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginSignupUserType;
