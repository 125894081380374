import React from "react";
import { FaCaretDown, FaCog, FaUserPlus } from "react-icons/fa";
import {
  setIsAdd,
  setIsConfirm,
  setMessage,
  setStartIndex,
} from "../../../../store/StoreAction";
import { Link } from "react-router-dom";
import useLoadAll from "../../../custom-hooks/useLoadAll";
import Header from "../../../header/Header";
import Navigation from "../../../navigation/Navigation";
import Footer from "../../../footer/Footer";
import ModalSuccess from "../../../widget/ModalSuccess";
import ModalError from "../../../widget/ModalError";
import ModalYesNo from "../../../widget/ModalYesNo";
import Spinner from "../../../widget/Spinner";
import NoData from "../../../widget/NoData";
import { StoreContext } from "../../../../store/StoreContext";
import ModalAddCollector from "./ModalAddCollector";
import useFetchDataLoadMore from "../../../custom-hooks/useFetchDataLoadMore";
import { isRoleCashier } from "../../login/login-functions";
import { getTypeId } from "../../members/members-functions";
import LoadMore from "../../../widget/LoadMore";

const SCollector = () => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [toggle, setToggle] = React.useState(false);
  const [itemEdit, setItemEdit] = React.useState(false);
  const isCashier = isRoleCashier(store.credentials.role_name);
  // const { loading, result } = useLoadAll(
  //   "/admin/collector/read-collector-all.php"
  // );
  const { loading, handleLoad, totalResult, handleSearch, handleChange } =
    useFetchDataLoadMore(
      !isCashier
        ? "/admin/collector/read-collector.php"
        : "/admin/collector/read-collector-limit-by-branch.php",
      !isCashier
        ? "/admin/collector/read-collector-all.php"
        : "/admin/collector/read-collector-by-branch.php",
      25,
      getTypeId(store),
      "/admin/collector/read-collector-search.php"
    );
  let count = 0;

  const handleAdd = () => {
    dispatch(setIsAdd(true));
    dispatch(setStartIndex(0));
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    dispatch(setStartIndex(0));
    dispatch(setIsAdd(true));
    setItemEdit(item);
  };

  const handleDelete = (e, item) => {
    e.preventDefault();
    setItemEdit(item.collector_id);
    dispatch(setIsConfirm(true));
    dispatch(setMessage("Are you sure to delete?"));
  };

  return (
    <>
      {store.isAdd && <ModalAddCollector edit={{ itemEdit, setItemEdit }} />}
      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}
      {store.isConfirm && (
        <ModalYesNo
          edit={{ itemEdit, setItemEdit }}
          url="/admin/collector/archive-collector.php"
        />
      )}
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="settings" />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">COLLECTOR</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                <button
                  className="btn right"
                  id="create-new"
                  onClick={handleAdd}
                >
                  <span>
                    <FaUserPlus />
                  </span>
                  <span>Create</span>
                </button>
              </div>
            </div>
          </div>

          <hr />

          <div className="member content-block clearfix mb--20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="content-box bg-highlight bg-lightcolor mb--20">
                  <div className="list mb--40">
                    <table id="" className="" cellSpacing="0" width="100%">
                      <thead className="title-box-light">
                        <tr>
                          <th className="">#</th>
                          <th className="">ID</th>
                          <th className="">Name</th>
                          <th className="">Mobile No.</th>
                          <th className="">Branch Code</th>
                          <th className="t-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {store.resultSet.length > 0 ? (
                          store.resultSet.map((item) => {
                            count += 1;
                            return (
                              <tr key={item.collector_aid}>
                                <td className="">{count}</td>
                                <td className="">{item.collector_id}</td>
                                <td className="">{item.collector_name}</td>
                                <td className="">{item.collector_contact}</td>
                                <td className="">{item.branch_code}</td>
                                <td className="t-right">
                                  <div className="dropdown">
                                    <span>
                                      <FaCog />
                                      <FaCaretDown />
                                    </span>
                                    <div className="dropdown-content">
                                      <p>
                                        <Link
                                          to="/settings"
                                          onClick={(e) => handleEdit(e, item)}
                                        >
                                          Edit
                                        </Link>
                                      </p>
                                      <p>
                                        <Link
                                          to="/settings"
                                          onClick={(e) => handleDelete(e, item)}
                                        >
                                          Delete
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" style={{ height: "100px" }}>
                              {loading && <Spinner />}
                              <NoData />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {!store.isSearch && (
                    <LoadMore
                      handleLoad={handleLoad}
                      loading={loading}
                      // result={result}
                      store={store}
                      totalResult={totalResult}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SCollector;
