import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import logo from "../../../img/fbas-logo.svg";

const LoginForgotPasswordSuccess = () => {
  return (
    <>
      <main className="main__login">
        <div className="main__login__wrapper">
          <div className="main__login__form flex-basis-75">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="login-icon mb--20">
                  <FaCheckCircle />
                </div>
                <h1 className="color-primary t-bold t-center">All Set!</h1>
                <p className="t-center mb--60">
                  Your new password has been created.
                </p>
                <form action="#">
                  <a href="/" className="btn">
                    LOGIN NOW
                  </a>
                </form>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
            </div>
          </div>

          <div className="main__login__banner lg-only t-center  flex-basis-25">
            <div className="welcome__note">
              <img src={logo} alt="" width="150" />
              <h3 className="mb--40 color-lightcolor">
                Financial Blessings And Support Association Philippines
              </h3>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginForgotPasswordSuccess;
