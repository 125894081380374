import fetchApi from "./fetchApi";
import {
  devApiUrl,
  doList,
  doLoadmore,
  setStorageRoute,
} from "./general-functions";
import {
  setSuccess,
  setMessage,
  setError,
  setSave,
  setIsConfirm,
  setIsAdd,
  setStatus,
  setIsAdd2,
  setIsLogin,
} from "../../store/StoreAction";
import { checkRoleToRedirect } from "../pages/login/login-functions";

export const fetchData = async (
  setLoading, // set null if not used.
  endpoint, // api endpoint.
  fd, // form data values (json).
  setResult, // set null if not used. For getting data.
  successMsg, // set empty if not used.
  errorMsg, // set empty if not used
  dispatch, // action for context,
  store, // state for context.
  successModal, // set false if not used.
  isLoadMore, // set false if not used. For load more functionality
  props = null // props optional
) => {
  setLoading !== null && setLoading(true);
  const data = await fetchApi(devApiUrl + endpoint, fd);

  // console.log(data);

  // used for result set by read api
  isLoadMore && setResult !== null && doLoadmore(data, setResult);
  !isLoadMore && setResult !== null && doList(data, setResult);

  // if result data is undefined
  if (typeof data === "undefined") {
    console.log("undefined");
    setLoading !== null && setLoading(false);
    dispatch(setStatus(false));
  }

  // if result data is empty and status is false
  if (!data.status) {
    console.log(data.message);
    setLoading !== null && setLoading(false);
    dispatch(setStatus(false));
    // add modal will be closed when used
    // if (store.isAdd) {
    //   dispatch(setIsAdd(false));
    // }
    dispatch(setError(true));
    dispatch(setMessage(errorMsg));
  }

  // if result data is not empty and status is true
  if (data.status) {
    // console.log(store.isLogin);
    // console.log("Fetch success");
    // console.log(store.status);
    setLoading !== null && setLoading(false);

    // add modal will be closed when used
    if (store.isAdd || store.status) {
      dispatch(setIsAdd(false));
      dispatch(setStatus(false));
      store.isSave ? dispatch(setSave(false)) : dispatch(setSave(true));
    }

    // delete modal will be closed when used
    if (store.isConfirm) {
      dispatch(setIsConfirm(false));
      store.isSave ? dispatch(setSave(false)) : dispatch(setSave(true));
    }

    // success modal will be closed when used
    if (successModal) {
      dispatch(setSuccess(true));
      dispatch(setIsAdd2(false));
      dispatch(setMessage(successMsg));
    }

    // redirect to other page after login
    if (store.isLogin) {
      setStorageRoute(data.data, data.mail);
      dispatch(setIsLogin(false));
      checkRoleToRedirect(data.mail, props);
    }
  }
};
