import React from "react";
import { StoreContext } from "../../store/StoreContext";
import fetchApi from "../helpers/fetchApi";
import { fetchData } from "../helpers/fetchData";
import {
  addDateByMonth,
  devApiUrl,
  subtractDateByMonth,
} from "../helpers/general-functions";

const usePaymentById = (url, param1 = null) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [data, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getData();
  }, [store.isSave]);

  const getData = async () => {
    fetchData(
      setLoading, // Boolean loading values optional
      url,
      { token: "", id: param1 }, // form data values
      setResult,
      "", // success msg optional
      "Pay by id Server connection error. Please contact FBAS technical support.", // additional error msg if needed optional
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      false // boolean to show load more functionality button
    );
  };

  const getPaymentDetails = (monthly) => {
    let value = [];
    let amount = 0;
    let count = 0;

    if (data.length > 0) {
      data.map((item) => {
        if (item.payment_amount > parseInt(monthly)) {
          amount = item.payment_amount / parseInt(monthly);
          count = amount;
          for (let i = 0; i < amount; i++) {
            count += 1;
            value.push({
              aid: item.payment_aid,
              cid: item.payment_cid,
              amount: item.payment_amount,
              orderDate: addDateByMonth(item.payment_created, i),
              date: item.payment_created,
              por: item.payment_or,
              mark: "",
            });
          }
        } else {
          value.push({
            aid: item.payment_aid,
            cid: item.payment_cid,
            amount: item.payment_amount,
            orderDate: item.payment_created,
            date: item.payment_created,
            por: item.payment_or,
            mark: "",
          });
        }
      });
      return value;
    }
  };

  return {
    loading,
    data,
    getPaymentDetails,
  };
};

export default usePaymentById;
