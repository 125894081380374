import React from "react";
import { FaCaretDown, FaCog, FaUserPlus } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import useLoadAll from "../../custom-hooks/useLoadAll";
import { formatDate, numberWithCommas } from "../../helpers/general-functions";
import NoData from "../../widget/NoData";
import Spinner from "../../widget/Spinner";

const MembersViewTransferHistory = ({ isTransfer, cid }) => {
  const { isViewTranfer, setViewTranfer } = isTransfer;
  const { loading, result } = useLoadAll(
    "/admin/transfer/read-transfer-by-cid.php",
    cid
  );
  let count = 0;

  const handleClose = () => {
    // isViewTranfer ? setViewTranfer(false) : setViewTranfer(true);
    setViewTranfer(true);
  };

  return (
    <>
      <form>
        <div className="modal modal-front">
          <div className="display-center display-wide">
            <div className="title-container t-exbold notify-info">
              <h4 className="modal-title">
                <span className="color--blue">Transfer History</span>
              </h4>
              <span className="btn--close" onClick={handleClose}>
                <GrFormClose />
              </span>
            </div>

            <div className="bg--white">
              <div className="content-box">
                <div className="list responsive-table">
                  <table id="" className="" cellSpacing="0" width="100%">
                    <thead className="title-box-light">
                      <tr>
                        <th className="t-left" width="20">
                          #
                        </th>
                        <th className="" width="200">
                          Date
                        </th>
                        <th className="" width="200">
                          From Name
                        </th>
                        <th className="" width="200">
                          To Name
                        </th>
                        <th className="" width="200">
                          Program Type
                        </th>
                        <th className="" width="90">
                          Amount
                        </th>
                        <th className="" width="400">
                          Remarks
                        </th>
                        {/* <th className="t-right" width="150">
                          Action
                        </th> */}
                      </tr>
                    </thead>
                    <tbody id="directory">
                      {result.length > 0 ? (
                        result.map((item, key) => {
                          count += 1;
                          return (
                            <tr key={key}>
                              <td className="t-left">{count}</td>
                              <td className="">
                                {formatDate(item.transfer_datetime)}
                              </td>
                              <td className="">{item.transfer_from_name}</td>
                              <td className="">{item.transfer_to_name}</td>
                              <td className="">{item.transfer_program}</td>
                              <td className="">
                                {numberWithCommas(item.transfer_amount)}
                              </td>
                              <td className="">{item.transfer_remarks}</td>
                              {/* <td className="t-right">
                                <div className="dropdown">
                                  <span>
                                    <FaCog />
                                    <FaCaretDown />
                                  </span>
                                  <div className="dropdown-content">
                                    <p>
                                      <a href="">Edit Remarks</a>
                                    </p>
                                    <p>
                                      <a href="">Delete</a>
                                    </p>
                                  </div>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8" style={{ height: "100px" }}>
                            {loading && <Spinner />}
                            <NoData />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MembersViewTransferHistory;
