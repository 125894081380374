import React from "react";
import Navigation from "../../navigation/Navigation";
import Header from "../../header/Header";
import MembersEditPersonal from "./MembersEditPersonal";
import MembersEditBeneficiaries from "./MembersEditBeneficiaries";
import MembersEditProgramInfo from "./MembersEditProgramInfo";
import MembersEditPayment from "./MembersEditPayment";
import useFetchDataById from "../../custom-hooks/useFetchDataById";
import { getUrlParam } from "../../helpers/general-functions";
import Page404 from "../404/Page404";
import Spinner from "../../widget/Spinner";
import ModalSuccess from "../../widget/ModalSuccess";
import { StoreContext } from "../../../store/StoreContext";
import ModalError from "../../widget/ModalError";

const MembersEdit = (props) => {
  const { store } = React.useContext(StoreContext);
  const [toggle, setToggle] = React.useState(false);
  const { data, loading } = useFetchDataById(
    "/admin/members/read-members-by-id.php",
    getUrlParam().get("id")
  );

  return (
    <>
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="members" />

      <div className="main-content" style={{ position: "relative" }}>
        {loading ? (
          <Spinner />
        ) : data.length > 0 ? (
          <div className="container-fluid">
            <div className="title">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <span className="tab-title">
                    {data[0].members_fname.toUpperCase() +
                      " " +
                      data[0].members_lname.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>

            <hr />

            <div className="member content-block clearfix mb--20">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="content-box bg-highlight mb--20">
                    <div className="tab-menu">
                      <MembersEditPersonal data={data[0]} />
                      <MembersEditBeneficiaries data={data[0]} />
                      <MembersEditProgramInfo datas={data[0]} />
                      <MembersEditPayment data={data[0]} props={props} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-block clearfix" id="dashboard-list">
              <div className="row"></div>
            </div>
          </div>
        ) : (
          // <Page404 />
          "404 page."
        )}
      </div>
      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}
    </>
  );
};

export default MembersEdit;
