import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { GrFormClose } from "react-icons/gr";

import { InputText, MyTextArea } from "../../helpers/FormInputs";
import SpinnerButton from "../../widget/SpinnerButton";
import { StoreContext } from "../../../store/StoreContext";
import { fetchData } from "../../helpers/fetchData";
import { handleKey } from "../../helpers/general-functions";
import { setIsAdd2 } from "../../../store/StoreAction";

const ModalAddMedicalBenefits = ({ setAddMedical, edit, cid }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { itemEdit, setItemEdit } = edit;

  const initVal = {
    medical_aid: itemEdit ? itemEdit.medical_aid : null,
    medical_cid: itemEdit ? itemEdit.medical_cid : cid,
    medical_amount: itemEdit ? itemEdit.medical_amount : "",
    medical_remarks: itemEdit ? itemEdit.medical_remarks : "",
  };

  const yupSchema = Yup.object({
    medical_amount: Yup.string().required("Required"),
    // remarks_hospital: Yup.string().required("Required"),
  });

  const handleClose = () => {
    setAddMedical(false);
    setItemEdit(false);
    dispatch(setIsAdd2(false));
  };

  return (
    <>
      <Formik
        initialValues={initVal}
        validationSchema={yupSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          fetchData(
            setLoading,
            itemEdit
              ? "/admin/medical/update-medical.php"
              : "/admin/medical/create-medical.php",
            values, // form data values
            null, // result set data
            "Successfuly updated.", // success msg
            "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
            dispatch, // context api action
            store, // context api state
            true, // boolean to show success modal
            false // boolean to show load more functionality button
          );
          setItemEdit(false);

          // handleInterval();
        }}
      >
        {(props) => {
          // console.log(props.isSubmitting);

          return (
            <Form>
              <div className="modal modal-front">
                <div className="display-center">
                  <div className="title-container t-exbold notify-info">
                    <h4 className="modal-title">
                      <span className="color--blue"> Cash Benefits </span>
                    </h4>
                    <span className="btn--close" onClick={handleClose}>
                      <GrFormClose />
                    </span>
                  </div>
                  <div className="bg--white">
                    <div className="content-box">
                      <InputText
                        label="Medical Amount"
                        type="text"
                        name="medical_amount"
                        onKeyPress={handleKey}
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <MyTextArea
                        label="Remarks"
                        type="text"
                        name="medical_remarks"
                        disabled={loading}
                      />
                    </div>

                    <div className="button-container">
                      <button
                        type="submit"
                        className="btn btn-full"
                        disabled={loading}
                      >
                        {itemEdit ? "SAVE" : "ADD"}{" "}
                        {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalAddMedicalBenefits;
