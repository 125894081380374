import React from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import CashierMembers from "./CashierMembers";
import Navigation from "../../navigation/Navigation";

const UserCashier = (props) => {
  const [toggle, setToggle] = React.useState(false);
  const search = React.useRef(null);

  return (
    <>
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="all-members" search={search} />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">ALL BRANCH MEMBERS</span>
              </div>
            </div>
          </div>

          <hr />

          <div className="member content-block clearfix mb--20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="content-box bg-highlight bg-lightcolor mb--20">
                  <CashierMembers search={search} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserCashier;
