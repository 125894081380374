import React from "react";
import { FaCaretDown, FaCog } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  setIsAdd,
  setIsConfirm,
  setMessage,
  setStartIndex,
} from "../../../../store/StoreAction";
import NoData from "../../../widget/NoData";
import Spinner from "../../../widget/Spinner";

const BranchCodeTableRows = ({ store, dispatch, loading, edit }) => {
  const { setItemEdit } = edit;
  let count = 0;

  const handleEdit = (e, item) => {
    e.preventDefault();
    dispatch(setIsAdd(true));
    dispatch(setStartIndex(0));
    setItemEdit(item);
  };

  const handleArchive = (e, item) => {
    e.preventDefault();
    setItemEdit(item.branch_aid);
    dispatch(setIsConfirm(true));
    dispatch(setStartIndex(0));
    dispatch(setMessage("Are you sure to archive?"));
  };

  return (
    <>
      {store.resultSet.length > 0 ? (
        store.resultSet.map((item) => {
          {
            /* {result.length > 0 ? (
        result.map((item) => { */
          }
          count += 1;
          return (
            <tr key={item.branch_aid}>
              <td className="">{count}</td>
              <td className="">{item.branch_code}</td>
              <td className="desktop-view">{item.branch_city}</td>
              <td className="desktop-view">{item.branch_province}</td>
              <td className="desktop-view">{item.branch_region}</td>
              <td className="t-right">
                <div className="dropdown">
                  <span>
                    <FaCog />
                    <FaCaretDown />
                  </span>
                  <div className="dropdown-content">
                    <p>
                      <Link to="/settings" onClick={(e) => handleEdit(e, item)}>
                        Edit
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/settings"
                        onClick={(e) => handleArchive(e, item)}
                      >
                        Archive
                      </Link>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="7" style={{ height: "100px" }}>
            {loading && <Spinner />}
            <NoData />
          </td>
        </tr>
      )}
    </>
  );
};

export default BranchCodeTableRows;
