import React from "react";
import { copyrightYear } from "../helpers/general-functions";

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="content-block">
          <h6>All Rights Reserved &copy; {copyrightYear()}</h6>
          <h6>Designed and Powered by Frontline Business Solutions, Inc.</h6>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
