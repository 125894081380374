import React from "react";
import Navigation from "../../navigation/Navigation";
import Header from "../../header/Header";
import { FaUserPlus } from "react-icons/fa";
import MembersActive from "./MembersActive";
// import MembersInactive from "./MembersInactive";
// import MembersDelinquent from "./MembersDelinquent";
// import MembersCompleted from "./MembersCompleted";
import Footer from "../../footer/Footer";
import ModalAddMember from "./ModalAddMember";
import { isRoleRegular } from "../login/login-functions";
import { StoreContext } from "../../../store/StoreContext";

const Members = (props) => {
  const { store } = React.useContext(StoreContext);
  const [toggle, setToggle] = React.useState(false);
  const [isAdd, setAdd] = React.useState(false);
  const search = React.useRef(null);
  const isRegular = isRoleRegular(store.credentials.role_name);

  const handleAdd = () => {
    setAdd(true);
  };

  return (
    <>
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="members" search={search} />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">MEMBERS </span>
              </div>
              {!isRegular && (
                <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                  <button
                    className="btn right"
                    id="create-new"
                    onClick={handleAdd}
                  >
                    <span>
                      <FaUserPlus />
                    </span>
                    <span>Create</span>
                  </button>
                </div>
              )}
            </div>
          </div>

          <hr />

          <div className="member content-block clearfix mb--20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="content-box bg-highlight bg-lightcolor mb--20">
                  {isAdd && <ModalAddMember add={{ setAdd }} props={props} />}
                  <MembersActive search={search} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Members;
