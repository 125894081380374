import React from "react";
import { StoreContext } from "../../store/StoreContext";
import { fetchData } from "../helpers/fetchData";

const useLoadMedical = (url, param1 = null) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [medical, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getData();
  }, [store.isSave]);

  const getData = async () => {
    fetchData(
      setLoading, // Boolean loading values optional
      url,
      { token: "", type: param1 }, // form data values
      setResult,
      "", // success msg optional
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed optional
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      false // boolean to show load more functionality button
    );
  };

  return {
    loading,
    medical,
  };
};

export default useLoadMedical;
