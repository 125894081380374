import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Login from "./components/pages/login/Login";
import LoginForgotPassword from "./components/pages/login/LoginForgotPassword";
import Members from "./components/pages/members/Members";
import MembersEdit from "./components/pages/members/MembersEdit";
import MembersView from "./components/pages/members/MembersView";
import Settings from "./components/pages/settings/Settings";
import LoginForgotPasswordNew from "./components/pages/login/LoginForgotPasswordNew";
import LoginForgotPasswordSuccess from "./components/pages/login/LoginForgotPasswordSuccess";
import LoginChangeDefaultPassword from "./components/pages/login/LoginChangeDefaultPassword";
import { StoreProvider } from "./store/StoreContext";
import Page404 from "./components/pages/404/Page404";
import SettingsRole from "./components/pages/settings/user-role/SettingsRole";
import SettingsBranchCode from "./components/pages/settings/branch/SettingsBranchCode";
import Program300 from "./components/pages/settings/program/p300/Program300";
import SettingsProgramList from "./components/pages/settings/program/SettingsProgramList";
import Program500 from "./components/pages/settings/program/p500/Program500";
import Program750 from "./components/pages/settings/program/p750/Program750";
import Program1500 from "./components/pages/settings/program/p1500/Program1500";
import MembersViewLedgerRemarks from "./components/pages/members/MembersViewLedgerHospitalizationRemarks";
import MembersViewLedgerHospitalizationRemarks from "./components/pages/members/MembersViewLedgerHospitalizationRemarks";
import MembersViewLedgerMedicalRemarks from "./components/pages/members/MembersViewLedgerMedicalRemarks";
import SettingsCoordinator from "./components/pages/settings/SettingsCoordinator";
import SCoordinator from "./components/pages/settings/coordinator/SCoordinator";
import SCollector from "./components/pages/settings/collector/SCollector";
import LoginSignup from "./components/pages/login/LoginSignup";
import Profile from "./components/pages/user-member/Profile";
import ProtectedRoute from "./components/pages/ProtectedRoute";
import UserCashier from "./components/pages/user-cashier/UserCashier";
import ProtectedRouteAdmin from "./components/pages/ProtectedRouteAdmin";
import CashierMembersEdit from "./components/pages/user-cashier/CashierMembersEdit";
import LoginCoordinatorSignup from "./components/pages/login/LoginCoordinatorSignup";
import LoginSignupUserType from "./components/pages/login/LoginSignupUserType";
import UserMember from "./components/pages/user-member/UserMember";
import CashierMembersView from "./components/pages/user-cashier/CashierMembersView";

const App = () => {
  return (
    <div>
      <StoreProvider>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/member-signup" component={LoginSignup} />
            <Route
              exact
              path="/member-signup-usertype"
              component={LoginSignupUserType}
            />
            <Route
              exact
              path="/coordinator-signup"
              component={LoginCoordinatorSignup}
            />
            <Route
              exact
              path="/forgot-password"
              component={LoginForgotPassword}
            />
            <Route
              exact
              path="/create-new-password"
              component={LoginForgotPasswordNew}
            />
            <Route
              exact
              path="/login-change-password-success"
              component={LoginForgotPasswordSuccess}
            />
            <Route
              exact
              path="/login-change-default-password"
              component={LoginChangeDefaultPassword}
            />
            {/* <ProtectedRouteAdmin exact path="/admin" component={Dashboard} /> */}
            <ProtectedRoute exact path="/" component={Login} />
            {/* <Route exact path="/members" component={Members} /> */}
            <ProtectedRoute
              exact
              path="/user/ca/members"
              component={UserCashier}
            />
            <ProtectedRoute exact path="/admin/members" component={Members} />
            <ProtectedRoute
              exact
              path="/admin/all-members"
              component={UserCashier}
            />
            <ProtectedRoute
              exact
              path="/admin/all-members-view"
              component={CashierMembersView}
            />
            {/* <Route exact path="/profile" component={Profile} /> */}
            <ProtectedRoute
              exact
              path="/member/ledger"
              component={UserMember}
            />
            <ProtectedRoute exact path="/admin/settings" component={Settings} />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-role"
              component={SettingsRole}
            />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-program-300"
              component={Program300}
            />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-program-500"
              component={Program500}
            />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-program-750"
              component={Program750}
            />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-program-1500"
              component={Program1500}
            />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-program-list"
              component={SettingsProgramList}
            />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-program-list"
              component={SettingsProgramList}
            />
            <ProtectedRoute
              exact
              path="/admin/settings-coordinator"
              component={SettingsCoordinator}
            />
            <ProtectedRoute
              exact
              path="/admin/settings-coordinator-list"
              component={SCoordinator}
            />
            <ProtectedRoute
              exact
              path="/admin/settings-collector-list"
              component={SCollector}
            />
            <ProtectedRouteAdmin
              exact
              path="/admin/settings-branch-code"
              component={SettingsBranchCode}
            />
            <ProtectedRoute
              exact
              path="/admin/members-edit"
              component={MembersEdit}
            />
            <Route
              exact
              path="/user/ca/members-edit"
              component={CashierMembersEdit}
            />
            <ProtectedRoute
              exact
              path="/admin/members-view"
              component={MembersView}
            />
            <Route
              exact
              path="/members-view-ledger-hospitalization-remarks"
              component={MembersViewLedgerHospitalizationRemarks}
            />
            <Route
              exact
              path="/members-view-ledger-medical-remarks"
              component={MembersViewLedgerMedicalRemarks}
            />
            <Route exact path="/404" component={Page404} />
          </Switch>
        </Router>
      </StoreProvider>
    </div>
  );
};

export default App;
