import React from "react";
import "../../scss/components/sidenavigation.scss";
// import { RiProfileFill } from "react-icons/ri";
import { FaClipboardList } from "react-icons/fa";
import logo from "../../img/fbas-logo.svg";
import { NavLink } from "react-router-dom";
import { StoreContext } from "../../store/StoreContext";
import {
  setIsSearch,
  setResultSet,
  setSave,
  setStartIndex,
} from "../../store/StoreAction";

const NavigationUserMember = ({ tog, menu, search }) => {
  const { store, dispatch } = React.useContext(StoreContext);

  const handlePageIndex = () => {
    if (search !== undefined) {
      search.current.value = "";
    }

    dispatch(setIsSearch(false));
    dispatch(setResultSet([]));
    dispatch(setStartIndex(0));
    store.isSave ? dispatch(setSave(false)) : dispatch(setSave(true));
  };

  return (
    <div className={tog ? "nav-show side-nav" : "side-nav"}>
      {/* toggle the "nav-show" in the element with "side-nav" class to show mobile menu */}
      <div className="logo">
        <img src={logo} alt="" />
        <h3 className="color-lightcolor">
          <strong>FBAS</strong>
        </h3>
      </div>
      <nav>
        <ul>
          {/* add the "active" class to currently open menu */}

          {/* <li id="menu-profile" className={menu === "profile" ? "active" : ""}>
            <NavLink to="/profile" onClick={handlePageIndex}>
              <span>
                <RiProfileFill />
              </span>
              <span>My Profile</span>
            </NavLink>
          </li> */}

          <li id="menu-ledger" className={menu === "ledger" ? "active" : ""}>
            <NavLink to="/member/ledger" onClick={handlePageIndex}>
              <span>
                <FaClipboardList />
              </span>
              <span>Ledger</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavigationUserMember;
