import React from "react";
import { FaSearch } from "react-icons/fa";
import { StoreContext } from "../../../store/StoreContext";
import { fetchCUD } from "../../helpers/fetchCUD";
import { fetchData } from "../../helpers/fetchData";
import SpinnerButton from "../../widget/SpinnerButton";

const MembersSearchCity = ({ setCity }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [search, setSearch] = React.useState("");
  const [isSearch, setIsSearch] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState([]);

  const handleClick = (e, item) => {
    setCity(item);
    setSearch(e.target.textContent);
    setIsSearch(false);
  };

  const handleChange = async (e) => {
    if (e.target.value.trim() === "") {
      setSearch("");
      setIsSearch(false);
      return;
    }
    setIsSearch(true);
    setSearch(e.target.value);

    fetchData(
      setLoading,
      "/admin/city/read-city-search.php",
      { token: "", search: e.target.value }, // form data values
      setResult, // result set data
      "", // success msg
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      false // boolean to show load more functionality button
    );
  };
  return (
    <div className="content-box">
      <div className=" t-center mb--20">
        <input
          type="text"
          placeholder="Search by city and populate fields below"
          onChange={handleChange}
          name="search_city"
          value={search}
          autoComplete="false"
          className="search-city"
        />
        {loading && <SpinnerButton />}

        {result.length > 0 && isSearch && (
          <div className="list-result bg-highlight">
            <ul>
              {result.map((item) => (
                <li
                  key={item.city_aid}
                  onClick={(e) => handleClick(e, item)}
                >{`${item.city_name}, ${item.city_province}`}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MembersSearchCity;
