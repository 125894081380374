import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import logo from "../../../img/fbas-logo.svg";
import { fetchData } from "../../helpers/fetchData";
import ModalSuccess from "../../widget/ModalSuccess";
import ModalError from "../../widget/ModalError";
import SpinnerButton from "../../widget/SpinnerButton";
import { InputText } from "../../helpers/FormInputs";
import { StoreContext } from "../../../store/StoreContext";

const LoginSignup = () => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);

  const initVal = {
    members_email: "",
  };

  const yupSchema = Yup.object({
    members_email: Yup.string().email("Email is invalid").required("Required"),
  });

  return (
    <>
      <main className="main__login">
        <div className="main__login__wrapper">
          <div className="main__login__form flex-basis-75">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h1 className="color-primary t-bold t-center">Member Signup</h1>
                <p className="t-center mb--60">
                  We will email you an instruction to create your account.
                </p>

                <Formik
                  initialValues={initVal}
                  validationSchema={yupSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    console.log(values);
                    fetchData(
                      setLoading,
                      "/admin/users/create-users-member.php",
                      values, // form data values
                      null, // result set data
                      "Please check your email to confirm account.", // success msg
                      "Email not found or already used.", // additional error msg if needed
                      dispatch, // context api action
                      store, // context api state
                      true, // boolean to show success modal
                      false // boolean to show load more functionality button
                    );
                  }}
                >
                  {(props) => {
                    // console.log(props.isSubmitting);
                    return (
                      <Form>
                        <div className="content-box mb--30">
                          <InputText
                            label="Email Address (Submitted to FBAS)"
                            type="email"
                            name="members_email"
                            disabled={loading}
                          />
                        </div>

                        <div className="button-container">
                          <button
                            type="submit"
                            className="btn btn-full"
                            disabled={loading}
                          >
                            SUBMIT {loading && <SpinnerButton />}
                          </button>
                        </div>

                        <a className="go_back" href="/login">
                          Already a member? Login here.
                        </a>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
            </div>
          </div>

          <div className="main__login__banner lg-only t-center  flex-basis-25">
            <div className="welcome__note">
              <img src={logo} alt="" width="150" />
              <h3 className="mb--40 color-lightcolor">
                Financial Blessings And Support Association Philippines
              </h3>
            </div>
          </div>
        </div>
      </main>
      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}
    </>
  );
};

export default LoginSignup;
