import React from "react";
import MembersTableRows from "./MembersTableRows";
import LoadMore from "../../widget/LoadMore";
import MembersSearch from "./MembersSearch";
import ModalError from "../../widget/ModalError";
import { StoreContext } from "../../../store/StoreContext";
import useFetchDataLoadMore from "../../custom-hooks/useFetchDataLoadMore";
import MembersSearchTableRows from "./MembersSearchTableRows";
// import { isRoleAdmin, isRoleRegular } from "../login/login-functions";
import { getTypeId, getUrl1, getUrl2 } from "./members-functions";
import { isRoleCashier } from "../login/login-functions";

const MembersActive = ({ search }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const isCashier = isRoleCashier(store.credentials.role_name);

  const { loading, handleLoad, totalResult, handleSearch, handleChange } =
    useFetchDataLoadMore(
      getUrl1(store),
      getUrl2(store),
      25, // per page to show
      getTypeId(store),
      isCashier
        ? "/admin/members/read-members-search-by-branch.php"
        : "/admin/members/read-members-search.php",
      store.credentials.users_branch_id // param 2
    );

  return (
    <>
      {store.error && <ModalError />}

      <div className="search-bar t-center mb--20">
        <MembersSearch
          handleSearch={handleSearch}
          handleChange={handleChange}
          store={store}
          search={search}
          loading={loading}
        />
      </div>
      <div className="list mb--20 responsive-table">
        <table id="" className="" cellSpacing="0" width="100%">
          <thead className="title-box-light">
            <tr>
              <th className="">#</th>
              <th className="">Names</th>
              <th className="">CID</th>
              {/* <th className="desktop-view">Address</th> */}
              <th className="desktop-view">City</th>
              {/* <th className="desktop-view">Updated</th> */}
              <th className="t-right">Actions</th>
            </tr>
          </thead>
          <tbody id="directory">
            {store.isSearch ? (
              <MembersSearchTableRows
                store={store}
                loading={loading}
                dispatch={dispatch}
              />
            ) : (
              <MembersTableRows
                dispatch={dispatch}
                store={store}
                loading={loading}
              />
            )}
          </tbody>
        </table>
      </div>
      {!store.isSearch && (
        <LoadMore
          handleLoad={handleLoad}
          loading={loading}
          // result={result}
          store={store}
          totalResult={totalResult}
        />
      )}
    </>
  );
};

export default MembersActive;
