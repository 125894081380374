import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GrFormClose } from "react-icons/gr";
import { fetchData } from "../../helpers/fetchData";
import SpinnerButton from "../../widget/SpinnerButton";
import { InputSelect, InputText } from "../../helpers/FormInputs";
import { handleKey } from "../../helpers/general-functions";
import useLoadAll from "../../custom-hooks/useLoadAll";
import { StoreContext } from "../../../store/StoreContext";
import { setProgramChange } from "../../../store/StoreAction";

const ModalChangeProgram = ({
  members_aid,
  currentAid,
  cid,
  setChangeProgram,
  programType,
  totalInstAmount,
  monthlyAmount,
  instNo,
  por,
  payment_created,
}) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { result } = useLoadAll("/admin/program-list/read-programlist-all.php");
  const [isUp, setUp] = React.useState(false);
  const [downAmount, setDownAmount] = React.useState(0);
  const [upAmount, setUpAmount] = React.useState(0);

  // React.useEffect(() => {}, [downAmount]);

  const initVal = {
    members_aid,
    currentAid,
    payment_cid: cid,
    programlist_name: programType,
    change_program_aid: "",
    payment_amount: "",
    amount: "",
    payment_or: "",
    payment_mode: "Change",
    // payment_created: getDateYmd(Date.now()),
    payment_created,
    payment_is_down: 1,
    isDown: "",
    currentOr: por,
  };

  const yupSchema = Yup.object({
    change_program_aid: Yup.string().required("Required"),
    // amount: Yup.string().required("Required"),
    payment_or: isUp ? Yup.string().required("Required") : null,
  });

  const handleClose = () => {
    setChangeProgram(false);
    setUp(false);
  };

  const handleChangeProgram = (e) => {
    let val = parseInt(e.target.value.split("-")[1]);
    let monthly = parseInt(monthlyAmount);
    let totalAmount = parseInt(totalInstAmount);
    let Num = parseInt(instNo);

    console.log(totalAmount);

    // downgrade plan
    if (val < monthly) {
      setUp(false);
      setDownAmount(val * Num + val);
    }

    // upgrade plan
    if (val > monthly) {
      setUp(true);
      setUpAmount(val * Num + val - totalAmount);
      setDownAmount(val * Num + val);
    }

    if (e.target.value === "") {
      setUp(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initVal}
        validationSchema={yupSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values);
          fetchData(
            setLoading,
            "/admin/payment/update-change-payment.php",
            // isUp
            //   ? "/admin/payment/create-payment.php"
            //   : "/admin/payment/update-change-payment.php",
            values, // form data values
            null, // result set data
            "Program successfuly changed.", // success msg
            "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
            dispatch, // context api action
            store, // context api state
            true, // boolean to show success modal
            false // boolean to show load more functionality button
          );

          dispatch(setProgramChange(true));
        }}
      >
        {(props) => {
          // console.log(props.handleChange);
          props.values.amount = isUp ? upAmount : downAmount;
          props.values.payment_amount = downAmount;
          props.values.isDown = isUp;

          return (
            <Form>
              <div className="modal modal-add-employee">
                <div className="display-center">
                  <div className="title-container t-exbold notify-info">
                    <h4 className="modal-title">
                      <span className="color--blue">Change Program</span>
                    </h4>
                    <span className="btn--close" onClick={handleClose}>
                      <GrFormClose />
                    </span>
                  </div>
                  <div className="bg--white">
                    <div className="content-box">
                      <InputText
                        label="Current Program"
                        type="text"
                        name="programlist_name"
                        disabled
                      />
                    </div>

                    <div className="content-box">
                      <InputSelect
                        label="New Program Type"
                        name="change_program_aid"
                        handleChangeProgram={(e) => handleChangeProgram(e)}
                        disabled={loading}
                      >
                        {result.length > 0 ? (
                          <>
                            <option value=""> -- </option>
                            {result.map((item) => {
                              if (item.programlist_name !== programType) {
                                return (
                                  <option
                                    key={item.programlist_aid}
                                    value={`${item.programlist_aid}-${item.programlist_inst_amount}`}
                                    // value={item.programlist_inst_amount}
                                  >
                                    {item.programlist_name}
                                  </option>
                                );
                              }
                            })}
                          </>
                        ) : (
                          <option value=""> -- </option>
                        )}
                      </InputSelect>
                    </div>

                    {isUp && (
                      <>
                        <div className="content-box">
                          <InputText
                            label="Amount to Pay"
                            type="text"
                            name="amount"
                            disabled={loading}
                            onKeyPress={handleKey}
                            readOnly
                          />
                        </div>

                        <div className="content-box">
                          <InputText
                            label="OR No."
                            type="text"
                            name="payment_or"
                            disabled={loading}
                          />
                        </div>
                      </>
                    )}

                    <div className="button-container">
                      <button
                        type="submit"
                        className="btn btn-full"
                        disabled={loading}
                      >
                        PROCESS {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalChangeProgram;
