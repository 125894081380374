import React from "react";
import { StoreContext } from "../../../store/StoreContext";
import {
  computeAge,
  computeRemainingBalance,
  formatDate,
  getMemberStatus,
  // getPaymentDetails,
  getServiceValue,
  getTotalHospital,
  getTotalMedical,
  numberWithCommas,
} from "../../helpers/general-functions";
import { FaCaretDown, FaCog } from "react-icons/fa";
import { AiFillEdit, AiFillPrinter } from "react-icons/ai";
import { RiExchangeFill } from "react-icons/ri";
import NoData from "../../widget/NoData";
import useLoadAll from "../../custom-hooks/useLoadAll";
import useMemberStatus from "../../custom-hooks/useMemberStatus";
import {
  setError,
  setIsAdd,
  setIsConfirm,
  setMessage,
} from "../../../store/StoreAction";
import ModalAddPayment from "./ModalAddPayment";
import ModalSuccess from "../../widget/ModalSuccess";
import ModalError from "../../widget/ModalError";
import ModalYesNo from "../../widget/ModalYesNo";
import usePaymentById from "../../custom-hooks/usePaymentById";
import ModalViewRemarks from "./ModalViewRemarks";
import ModalAddCashBenefits from "./ModalAddCashBenefits";
import ModalViewAddHospital from "./ModalViewAddHospital";
import ModalViewAddMedical from "./ModalViewAddMedical";
import ModalAddMedicalBenefits from "./ModalAddMedicalBenefits";
import ModalAddHospitalBenefits from "./ModalAddHospitalBenefits";
import useLoadHospital from "../../custom-hooks/useLoadHospital";
import useLoadMedical from "../../custom-hooks/useLoadMedical";
import { MdAddCircle } from "react-icons/md";
import ModalChangeProgram from "./ModalChangeProgram";
import MembersViewTransferHistory from "./MembersViewTransferHistory";
import ModalTransferProgram from "./ModalTransferProgram";
import useLoadChange from "../../custom-hooks/useLoadChange";
import useLoadTransfer from "../../custom-hooks/useLoadTransfer";
import { isRoleMCVP, isRoleRegular } from "../login/login-functions";

const MembersViewLedger = ({ datas }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [itemEdit, setItemEdit] = React.useState(null);
  const [isAddHospital, setAddHospital] = React.useState(false);
  const [isAddMedical, setAddMedical] = React.useState(false);
  const [isViewHospital, setViewHospital] = React.useState(false);
  const [isViewMedical, setViewMedical] = React.useState(false);
  const [isChangeProgram, setChangeProgram] = React.useState(false);
  const [isTransferProgram, setTransferProgram] = React.useState(false);
  const [isViewTranfer, setViewTranfer] = React.useState(false);
  let totalServiceValue = 0;
  const isRegular = isRoleRegular(store.credentials.role_name);
  const isMCVP = isRoleMCVP(store.credentials.role_name);

  const { getPaymentDetails, data } = usePaymentById(
    "/admin/payment/read-payment-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { result } = useLoadAll(
    "/admin/program/read-program-by-type.php",
    datas.programlist_inst_amount
  );

  const { status, getNumInst } = useMemberStatus(
    "/admin/payment/read-payment-by-status.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { hospital } = useLoadHospital(
    "/admin/hospital/read-hospital-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { medical } = useLoadMedical(
    "/admin/medical/read-medical-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { change } = useLoadChange(
    "/admin/change/read-change-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { transfer } = useLoadTransfer(
    "/admin/transfer/read-transfer-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  let count = -1;
  let total = 0;

  const computeRemainingSValue = () => {
    let total = 0;

    if (result.length > 0) {
      total =
        Number(result[result.length - 1].program_value) -
        Number(totalServiceValue);
    }

    return total;
  };

  const handleAdd = () => {
    if (datas.members_pay_amount === "") {
      dispatch(setError(true));
      dispatch(setMessage("Please complete the Payment Info of members."));
      return;
    }
    dispatch(setIsAdd(true));
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    dispatch(setIsAdd(true));
    setItemEdit(item);
  };

  const handleDelete = (e, aid) => {
    e.preventDefault();
    setItemEdit(aid);
    dispatch(setIsConfirm(true));
    dispatch(setMessage("Are you sure to delete?"));
  };

  const handleHistoryHospital = (e) => {
    e.preventDefault();
    setViewHospital(true);
    setItemEdit(null);
  };

  const handleHistoryMedical = (e) => {
    e.preventDefault();
    setViewMedical(true);
    setItemEdit(null);
  };

  const handleHistoryTransfer = (e) => {
    e.preventDefault();
    setViewTranfer(false);
  };

  const handleProgram = () => {
    if (data.length < 1) {
      dispatch(setError(true));
      dispatch(setMessage("There is no payment yet."));
      return;
    }
    setChangeProgram(true);
  };

  const handleTransfer = () => {
    if (data.length < 1) {
      dispatch(setError(true));
      dispatch(setMessage("There is no payment yet."));
      return;
    }
    setTransferProgram(true);
  };

  return (
    <>
      {isViewTranfer ||
        (transfer.length > 0 && (
          <MembersViewTransferHistory
            isTransfer={{ isViewTranfer, setViewTranfer }}
            cid={datas.branch_code + "-" + datas.members_cid}
          />
        ))}

      {isTransferProgram && (
        <ModalTransferProgram
          setTransferProgram={setTransferProgram}
          programType={datas.programlist_name}
          totalInstAmount={
            status.length > 0 && status[0].instNum !== null && status[0].instNum
          }
          cid={datas.branch_code + "-" + datas.members_cid}
          fromName={datas.members_fname + " " + datas.members_lname}
          change_aid={change.length > 0 ? change[0].change_aid : 0}
        />
      )}

      {isChangeProgram && (
        <ModalChangeProgram
          members_aid={datas.members_aid}
          currentAid={datas.programlist_aid}
          cid={datas.branch_code + "-" + datas.members_cid}
          setChangeProgram={setChangeProgram}
          programType={datas.programlist_name}
          totalInstAmount={
            status.length > 0 && status[0].instNum !== null && status[0].instNum
          }
          monthlyAmount={datas.programlist_inst_amount}
          instNo={
            status.length > 0 &&
            status[0].instNum !== null &&
            getNumInst(datas.programlist_inst_amount)
          }
          por={
            status.length > 0 &&
            status[0].payment_or !== "" &&
            status[0].payment_or
          }
          payment_created={
            status.length > 0 &&
            status[0].payment_created !== "" &&
            status[0].payment_created
          }
        />
      )}

      {store.isAdd && (
        <ModalAddPayment
          edit={{ itemEdit, setItemEdit }}
          cid={datas.branch_code + "-" + datas.members_cid}
          instAmount={datas.programlist_inst_amount}
          payMode={datas.members_pay_mode}
        />
      )}

      {store.isConfirm && (
        <ModalYesNo
          edit={{ itemEdit, setItemEdit }}
          url={
            isViewHospital
              ? "/admin/hospital/delete-hospital.php"
              : isViewMedical
              ? "/admin/medical/delete-medical.php"
              : "/admin/payment/delete-payment.php"
          }
        />
      )}

      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}

      {isAddHospital && store.isAdd2 && (
        <ModalAddHospitalBenefits
          setAddHospital={setAddHospital}
          edit={{ itemEdit, setItemEdit }}
          cid={datas.branch_code + "-" + datas.members_cid}
        />
      )}

      {isViewHospital && (
        <ModalViewAddHospital
          setViewHospital={setViewHospital}
          cid={datas.branch_code + "-" + datas.members_cid}
          edit={{ itemEdit, setItemEdit }}
          setAddHospital={setAddHospital}
        />
      )}

      {isAddMedical && store.isAdd2 && (
        <ModalAddMedicalBenefits
          setAddMedical={setAddMedical}
          edit={{ itemEdit, setItemEdit }}
          cid={datas.branch_code + "-" + datas.members_cid}
        />
      )}

      {isViewMedical && (
        <ModalViewAddMedical
          setViewMedical={setViewMedical}
          cid={datas.branch_code + "-" + datas.members_cid}
          edit={{ itemEdit, setItemEdit }}
          setAddMedical={setAddMedical}
        />
      )}

      <input
        type="radio"
        name="tabs"
        id="tab-ledger"
        // defaultChecked={store.isViewLedger ? true : false}
        defaultChecked
      />
      <label htmlFor="tab-ledger" className="menu-label">
        LEDGER
      </label>
      <div className="tab">
        <div className="content-block ledger">
          <div className="row mb--20">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="content-block-gray">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-child">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              CID No.:{" "}
                            </label>
                            <strong>
                              {datas.branch_code + "-" + datas.members_cid}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Name:&nbsp;
                            </label>
                            <strong>
                              {datas.members_fname + " " + datas.members_lname}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row  col-100">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Address: &nbsp;
                            </label>
                            <strong>
                              {datas.members_address +
                                ", " +
                                datas.members_city +
                                ", " +
                                datas.members_province}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Birth Date: &nbsp;
                            </label>
                            <strong>
                              {datas.members_birthdate
                                ? formatDate(datas.members_birthdate)
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Age: &nbsp;
                            </label>
                            <strong>
                              {computeAge(datas.members_birthdate)}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Contact No: &nbsp;
                            </label>
                            <strong>
                              {datas.members_contact_num
                                ? datas.members_contact_num
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Gender: &nbsp;
                            </label>
                            <strong>
                              {datas.members_gender
                                ? datas.members_gender
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Beneficiary: &nbsp;
                            </label>
                            <strong>
                              {datas.members_ben1_name
                                ? datas.members_ben1_name
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-child">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Reg. Date: &nbsp;
                            </label>
                            <strong>
                              {status.length > 0 &&
                              status[0].payment_created !== null
                                ? formatDate(status[0].payment_created)
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Coordinator: &nbsp;
                            </label>
                            <strong>
                              {datas.coordinator_name
                                ? datas.coordinator_name
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Program Type: &nbsp;
                            </label>
                            <strong>
                              {datas.programlist_inst_amount
                                ? numberWithCommas(
                                    datas.programlist_inst_amount
                                  )
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Collector: &nbsp;
                            </label>
                            <strong>
                              {datas.members_collector ===
                              "Default to Coordinator"
                                ? datas.coordinator_name
                                : datas.members_collector}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Status: &nbsp;
                            </label>
                            <strong>
                              {status.length > 0
                                ? getMemberStatus(
                                    status,
                                    datas.programlist_inst_amount
                                  )
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              No. of Inst. Paid: &nbsp;
                            </label>
                            <strong>
                              {status.length > 0 && status[0].instNum !== null
                                ? getNumInst(datas.programlist_inst_amount)
                                : 0}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Cluster Name: &nbsp;
                            </label>
                            <strong>
                              {datas.members_prog_cluster
                                ? datas.members_prog_cluster
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Area: &nbsp;
                            </label>
                            <strong>
                              {datas.members_prog_area
                                ? datas.members_prog_area
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="ledger-content" className="row mb--30">
            <div className="col-lg-12 col-md-12 col-sm-12">
              {!isRegular && (
                <div className="button-container t-right print-button mb--20">
                  <button className="btn" onClick={handleAdd}>
                    <span>
                      <MdAddCircle />
                    </span>
                    <span>Add Payment</span>
                  </button>

                  <button className="btn" onClick={handleProgram}>
                    <span>
                      <AiFillEdit />
                    </span>
                    <span>Change Program</span>
                  </button>

                  {!isMCVP && (
                    <button className="btn" onClick={handleTransfer}>
                      <span>
                        <RiExchangeFill />
                      </span>
                      <span>Transfer Program</span>
                    </button>
                  )}

                  <button
                    className="btn"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <span>
                      <AiFillPrinter />
                    </span>
                    <span>Print Ledger</span>
                  </button>
                </div>
              )}
              <div className="member content-block bg-highlight  bg-lightcolor clearfix mb--20">
                <div className="list responsive-table">
                  <table id="" className="" cellSpacing="0" width="100%">
                    <thead className="title-box-light">
                      <tr>
                        <th className="t-center">Date</th>
                        <th className="t-center">O.R. #</th>
                        <th className="">No. of Installment</th>
                        <th className="t-center">Monthly Installment</th>
                        <th className="t-center">Total Payment</th>
                        <th className="t-center">Service Value</th>
                        {!isRegular && <th className="t-center">Action</th>}
                      </tr>
                    </thead>
                    <tbody id="directory">
                      {data.length > 0 &&
                      status.length > 0 &&
                      status[0].instNum !== null ? (
                        getPaymentDetails(datas.programlist_inst_amount).map(
                          (item, key) => {
                            count += 1;
                            total = datas.programlist_inst_amount * count;
                            if (count > 0) {
                              let val = parseInt(
                                getServiceValue(
                                  count,
                                  result,
                                  status[0].payment_created
                                )
                              );
                              if (val !== 0) {
                                totalServiceValue = val;
                              }
                            }
                            return (
                              <tr key={key}>
                                <td className="t-center">
                                  {formatDate(item.date)}
                                </td>
                                <td className="t-center">{item.por}</td>
                                <td className="t-center">
                                  {count === 0 ? "Registration" : count}
                                </td>

                                <td className="t-center">
                                  {numberWithCommas(
                                    datas.programlist_inst_amount
                                  )}
                                </td>
                                <td className="t-center">
                                  {count > 0
                                    ? numberWithCommas(total)
                                    : numberWithCommas(
                                        datas.programlist_inst_amount
                                      )}
                                </td>
                                <td className="t-center">
                                  {count > 0
                                    ? numberWithCommas(
                                        getServiceValue(
                                          // item.payment_amount * count,
                                          // total,
                                          // datas.programlist_inst_amount,
                                          count,
                                          result,
                                          status[0].payment_created
                                        )
                                      )
                                    : 0}
                                </td>
                                {!isRegular && (
                                  <td className="t-center">
                                    <div
                                      className="dropdown"
                                      // style={
                                      //   count < 1
                                      //     ? { visibility: "hidden" }
                                      //     : null
                                      // }
                                    >
                                      <span>
                                        <FaCog />
                                        <FaCaretDown />
                                      </span>
                                      <div className="dropdown-content">
                                        {/* {count > 0 ? ( */}
                                        <>
                                          <p>
                                            <a
                                              href="/"
                                              onClick={(e) =>
                                                handleEdit(e, item)
                                              }
                                            >
                                              {" "}
                                              Edit{" "}
                                            </a>{" "}
                                          </p>
                                          <p>
                                            <a
                                              href="/"
                                              onClick={(e) =>
                                                handleDelete(e, item.aid)
                                              }
                                            >
                                              {" "}
                                              Delete{" "}
                                            </a>{" "}
                                          </p>
                                        </>
                                        {/* ) : null} */}
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan="8" style={{ height: "100px" }}>
                            <NoData />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">Membership Remaining Balance:</label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                PHP {numberWithCommas(computeRemainingBalance(datas, total))}
              </h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                <strong className="color-primary">Membership Remarks:</strong>{" "}
                <span className="color-secondary t-bold">
                  {change.length > 0
                    ? `${change[0].change_from} to ${change[0].programlist_name}`
                    : "No change program"}
                </span>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">
                Hospitalization Available Cash Benefits:
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                PHP{" "}
                {hospital.length > 0
                  ? getTotalHospital(
                      hospital,
                      datas.programlist_hospital_amount
                    )
                  : numberWithCommas(datas.programlist_hospital_amount)}
                {/* PHP {numberWithCommas(datas.members_hospital_amount)} */}
              </h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                {/* <a
                  href="/members-view-ledger-hospitalization-remarks"
                  className="btn"
                >
                  View Remarks
                </a> */}
                <strong className="color-primary">
                  Hospitalization Cash Benefits Remarks:
                </strong>{" "}
                {hospital.length > 0 ? hospital[0].hospital_remarks : ""}{" "}
                {!isRegular && (
                  <a
                    href="/"
                    className="color-secondary t-bold"
                    onClick={(e) => handleHistoryHospital(e)}
                  >
                    <u>
                      {" "}
                      {hospital.length > 0 ? "view history..." : "Add remarks"}
                    </u>
                  </a>
                )}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">
                Medical Reimbursement Available Cash Benefit:
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                {/* PHP {numberWithCommas(datas.members_medreim_amount)} */}
                PHP{" "}
                {medical.length > 0
                  ? getTotalMedical(medical, datas.programlist_medical_amount)
                  : numberWithCommas(datas.programlist_medical_amount)}
              </h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                {/* <a href="/members-view-ledger-medical-remarks" className="btn">
                  View Remarks
                </a> */}
                <strong className="color-primary">
                  Medical Cash Benefits Remarks:
                </strong>{" "}
                {medical.length > 0 ? medical[0].medical_remarks : ""}{" "}
                {!isRegular && (
                  <a
                    href="/"
                    className="color-secondary t-bold"
                    onClick={(e) => handleHistoryMedical(e)}
                  >
                    <u>
                      {" "}
                      {medical.length > 0 ? "view history..." : "Add remarks"}
                    </u>
                  </a>
                )}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">
                Service Value Remaining Balance:
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                {/* PHP {numberWithCommas(totalServiceValue)} */}
                PHP {numberWithCommas(computeRemainingSValue())}
              </h4>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                <strong className="color-primary">Transfer History: </strong>
                {transfer.length > 0 ? (
                  <a
                    href="/"
                    className="color-secondary t-bold"
                    onClick={(e) => handleHistoryTransfer(e)}
                  >
                    <u> View... </u>
                  </a>
                ) : (
                  <span className="color-secondary t-bold">None</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembersViewLedger;
