import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FaSave } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { InputSelect, InputText } from "../../helpers/FormInputs";
import fetchApi from "../../helpers/fetchApi";
import {
  devApiUrl,
  getUrlParam,
  handleKey,
} from "../../helpers/general-functions";
import SpinnerButton from "../../widget/SpinnerButton";
import { StoreContext } from "../../../store/StoreContext";
import { setMessage, setSuccess } from "../../../store/StoreAction";

const MembersEditBeneficiaries = ({ data }) => {
  const { dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);

  const initVal = {
    members_aid: data.members_aid,
    members_ben1_name: data.members_ben1_name,
    members_ben1_age: data.members_ben1_age,
    members_ben1_rel: data.members_ben1_rel,
    members_ben1_bod: data.members_ben1_bod,
    members_ben1_contact: data.members_ben1_contact,
    members_ben2_name: data.members_ben2_name,
    members_ben2_age: data.members_ben2_age,
    members_ben2_rel: data.members_ben2_rel,
    members_ben2_bod: data.members_ben2_bod,
    members_ben2_contact: data.members_ben2_contact,
    // members_ben1_pay_name: data.members_ben1_pay_name,
    members_ben1_pay_name: data.members_fname + " " + data.members_lname,
    members_ben1_pay_age: data.members_ben1_pay_age,
    members_ben1_pay_rel: data.members_ben1_pay_rel,
    members_ben2_pay_name: "",
    members_ben2_pay_age: "",
    members_ben2_pay_rel: "",
  };

  const yupSchema = Yup.object({
    // members_ben1_name: Yup.string().required("Required"),
    // members_ben1_age: Yup.string().required("Required"),
    // members_ben1_rel: Yup.string().required("Required"),
    members_ben1_pay_name: Yup.string().required("Required"),
    // members_ben1_pay_age: Yup.string().required("Required"),
    // members_ben1_pay_rel: Yup.string().required("Required"),
  });
  return (
    <>
      <input
        type="radio"
        name="tabs"
        id="tab-beneficiaries"
        defaultChecked={getUrlParam().get("tab") === "bene" ? true : false}
      />
      <label htmlFor="tab-beneficiaries" className="menu-label">
        BENEFICIARIES
      </label>
      <div className="tab" style={{ padding: "30px 10px" }}>
        <Formik
          initialValues={initVal}
          validationSchema={yupSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const member = await fetchApi(
              devApiUrl + "/admin/members/update-members-beneficiary.php",
              values
            );

            // console.log(member);
            if (typeof member === "undefined") {
              console.log("undefined");
              setLoading(false);
            }

            if (!member.status) {
              console.log(member.message);
              setLoading(false);
            }

            if (member.status) {
              console.log("Member updated");
              dispatch(setSuccess(true));
              dispatch(setMessage("Successfuly saved."));
              setLoading(false);
              window.location.href =
                "/admin/members-edit?id=" +
                getUrlParam().get("id") +
                "&tab=prog";
            }
          }}
        >
          {(props) => {
            // console.log(props.isSubmitting);

            return (
              <Form>
                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <HiUserGroup />
                      <strong> Beneficiaries</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row  mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      <InputText
                        label="Full Name (Primary Beneficiary)"
                        type="text"
                        name="members_ben1_name"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Age"
                        type="text"
                        name="members_ben1_age"
                        disabled={props.isSubmitting}
                        onKeyPress={handleKey}
                      />
                      {/* <InputSelect
                        label="Age"
                        name="members_ben1_age"
                        disabled={props.isSubmitting}
                      >
                        <option value=""> -- </option>
                        <option value="70">70</option>
                        <option value="69">69</option>
                        <option value="68">68</option>
                        <option value="67">67</option>
                        <option value="66">66</option>
                        <option value="65">65</option>
                        <option value="64">64</option>
                        <option value="63">63</option>
                        <option value="62">62</option>
                        <option value="61">61</option>
                        <option value="60">60</option>
                        <option value="59">59</option>
                        <option value="58">58</option>
                        <option value="57">57</option>
                        <option value="56">56</option>
                        <option value="55">55</option>
                        <option value="54">54</option>
                        <option value="53">53</option>
                        <option value="52">52</option>
                        <option value="51">51</option>
                        <option value="50">50</option>
                        <option value="49">49</option>
                        <option value="48">48</option>
                        <option value="47">47</option>
                        <option value="46">46</option>
                        <option value="45">45</option>
                        <option value="44">44</option>
                        <option value="43">43</option>
                        <option value="42">42</option>
                        <option value="41">41</option>
                        <option value="40">40</option>
                        <option value="39">39</option>
                        <option value="38">38</option>
                        <option value="37">37</option>
                        <option value="36">36</option>
                        <option value="35">35</option>
                        <option value="34">34</option>
                        <option value="33">33</option>
                        <option value="32">32</option>
                        <option value="31">31</option>
                        <option value="30">30</option>
                        <option value="29">29</option>
                        <option value="28">28</option>
                        <option value="27">27</option>
                        <option value="26">26</option>
                        <option value="25">25</option>
                        <option value="24">24</option>
                        <option value="23">23</option>
                        <option value="22">22</option>
                        <option value="21">21</option>
                        <option value="20">20</option>
                        <option value="19">19</option>
                        <option value="18">18</option>
                        <option value="17">17</option>
                        <option value="16">16</option>
                        <option value="15">15</option>
                        <option value="14">14</option>
                        <option value="13">13</option>
                        <option value="12">12</option>
                        <option value="11">11</option>
                        <option value="10">10</option>
                      </InputSelect> */}
                    </div>

                    <div className="content-box">
                      <InputSelect label="Relationship" name="members_ben1_rel">
                        <option value=""> -- </option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Sister">Sister</option>
                        <option value="Brother">Brother</option>
                        <option value="Spouse">Spouse</option>
                        <option value="Child">Child</option>
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Birthday"
                        type="date"
                        name="members_ben1_bod"
                        disabled={props.isSubmitting}
                      />
                    </div>
                    <div className="content-box">
                      <InputText
                        label="Contact Number"
                        type="text"
                        name="members_ben1_contact"
                        disabled={props.isSubmitting}
                        onKeyPress={handleKey}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Full Name (Secondary Beneficiary)"
                        type="text"
                        name="members_ben2_name"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Age"
                        type="text"
                        name="members_ben2_age"
                        disabled={props.isSubmitting}
                        onKeyPress={handleKey}
                      />
                      {/* <InputSelect
                        label="Age"
                        name="members_ben2_age"
                        disabled={props.isSubmitting}
                      >
                        <option value=""> -- </option>
                        <option value="70">70</option>
                        <option value="69">69</option>
                        <option value="68">68</option>
                        <option value="67">67</option>
                        <option value="66">66</option>
                        <option value="65">65</option>
                        <option value="64">64</option>
                        <option value="63">63</option>
                        <option value="62">62</option>
                        <option value="61">61</option>
                        <option value="60">60</option>
                        <option value="59">59</option>
                        <option value="58">58</option>
                        <option value="57">57</option>
                        <option value="56">56</option>
                        <option value="55">55</option>
                        <option value="54">54</option>
                        <option value="53">53</option>
                        <option value="52">52</option>
                        <option value="51">51</option>
                        <option value="50">50</option>
                        <option value="49">49</option>
                        <option value="48">48</option>
                        <option value="47">47</option>
                        <option value="46">46</option>
                        <option value="45">45</option>
                        <option value="44">44</option>
                        <option value="43">43</option>
                        <option value="42">42</option>
                        <option value="41">41</option>
                        <option value="40">40</option>
                        <option value="39">39</option>
                        <option value="38">38</option>
                        <option value="37">37</option>
                        <option value="36">36</option>
                        <option value="35">35</option>
                        <option value="34">34</option>
                        <option value="33">33</option>
                        <option value="32">32</option>
                        <option value="31">31</option>
                        <option value="30">30</option>
                        <option value="29">29</option>
                        <option value="28">28</option>
                        <option value="27">27</option>
                        <option value="26">26</option>
                        <option value="25">25</option>
                        <option value="24">24</option>
                        <option value="23">23</option>
                        <option value="22">22</option>
                        <option value="21">21</option>
                        <option value="20">20</option>
                        <option value="19">19</option>
                        <option value="18">18</option>
                        <option value="17">17</option>
                        <option value="16">16</option>
                        <option value="15">15</option>
                        <option value="14">14</option>
                        <option value="13">13</option>
                        <option value="12">12</option>
                        <option value="11">11</option>
                        <option value="10">10</option>
                      </InputSelect> */}
                    </div>

                    <div className="content-box">
                      <InputSelect label="Relationship" name="members_ben2_rel">
                        <option value=""> -- </option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Sister">Sister</option>
                        <option value="Brother">Brother</option>
                        <option value="Spouse">Spouse</option>
                        <option value="Child">Child</option>
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Birthday"
                        type="date"
                        name="members_ben2_bod"
                        disabled={props.isSubmitting}
                      />
                    </div>
                    <div className="content-box">
                      <InputText
                        label="Contact Number"
                        type="text"
                        name="members_ben2_contact"
                        disabled={props.isSubmitting}
                        onKeyPress={handleKey}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <GiReceiveMoney />
                      <strong> Payor</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row  mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      <InputText
                        label="Full Name"
                        type="text"
                        name="members_ben1_pay_name"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Age"
                        type="text"
                        name="members_ben1_pay_age"
                        disabled={props.isSubmitting}
                        onKeyPress={handleKey}
                      />
                      {/* <InputSelect
                        label="Age"
                        name="members_ben1_pay_age"
                        disabled={props.isSubmitting}
                      >
                        <option value=""> -- </option>
                        <option value="70">70</option>
                        <option value="69">69</option>
                        <option value="68">68</option>
                        <option value="67">67</option>
                        <option value="66">66</option>
                        <option value="65">65</option>
                        <option value="64">64</option>
                        <option value="63">63</option>
                        <option value="62">62</option>
                        <option value="61">61</option>
                        <option value="60">60</option>
                        <option value="59">59</option>
                        <option value="58">58</option>
                        <option value="57">57</option>
                        <option value="56">56</option>
                        <option value="55">55</option>
                        <option value="54">54</option>
                        <option value="53">53</option>
                        <option value="52">52</option>
                        <option value="51">51</option>
                        <option value="50">50</option>
                        <option value="49">49</option>
                        <option value="48">48</option>
                        <option value="47">47</option>
                        <option value="46">46</option>
                        <option value="45">45</option>
                        <option value="44">44</option>
                        <option value="43">43</option>
                        <option value="42">42</option>
                        <option value="41">41</option>
                        <option value="40">40</option>
                        <option value="39">39</option>
                        <option value="38">38</option>
                        <option value="37">37</option>
                        <option value="36">36</option>
                        <option value="35">35</option>
                        <option value="34">34</option>
                        <option value="33">33</option>
                        <option value="32">32</option>
                        <option value="31">31</option>
                        <option value="30">30</option>
                        <option value="29">29</option>
                        <option value="28">28</option>
                        <option value="27">27</option>
                        <option value="26">26</option>
                        <option value="25">25</option>
                        <option value="24">24</option>
                        <option value="23">23</option>
                        <option value="22">22</option>
                        <option value="21">21</option>
                        <option value="20">20</option>
                        <option value="19">19</option>
                        <option value="18">18</option>
                        <option value="17">17</option>
                        <option value="16">16</option>
                        <option value="15">15</option>
                        <option value="14">14</option>
                        <option value="13">13</option>
                        <option value="12">12</option>
                        <option value="11">11</option>
                        <option value="10">10</option>
                      </InputSelect> */}
                    </div>

                    <div className="content-box">
                      <InputSelect
                        label="Relationship"
                        name="members_ben1_pay_rel"
                        disabled={props.isSubmitting}
                      >
                        <option value=""> -- </option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Sister">Sister</option>
                        <option value="Brother">Brother</option>
                        <option value="Spouse">Spouse</option>
                        <option value="Child">Child</option>
                      </InputSelect>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <button
                      className="btn btn-full"
                      id="create-new"
                      type="submit"
                      disabled={props.isSubmitting}
                    >
                      <span>
                        <FaSave />
                      </span>
                      <span>Save {loading && <SpinnerButton />}</span>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default MembersEditBeneficiaries;
