import React from "react";
import Navigation from "../../navigation/Navigation";
import Header from "../../header/Header";
import SettingsRole from "./user-role/SettingsRole";
import Footer from "../../footer/Footer";
import SettingsBranchCode from "./branch/SettingsBranchCode";
import { Link } from "react-router-dom";
import {
  isRoleAdminItem,
  isRoleCashierItem,
  isRoleMatch,
} from "../login/login-functions";
import { StoreContext } from "../../../store/StoreContext";

const Settings = () => {
  const { store } = React.useContext(StoreContext);
  const [toggle, setToggle] = React.useState(false);

  return (
    <>
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="settings" />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <span className="tab-title">SETTINGS </span>
              </div>
            </div>
          </div>

          <hr />

          <div className="setting">
            <div className="row">
              <div
                className={
                  isRoleAdminItem(store.credentials.role_name)
                    ? "col-lg-6 col-md-6 col-sm-12 col-xs-12"
                    : "hide"
                }
              >
                <div className="bg-lightgray mb--20">
                  <h4 className="color-primary t-bold"> User Role Settings</h4>
                  <p>
                    Manage what actions and capabilities every user can do in
                    the system.
                  </p>
                  <div className="mb--10">
                    <Link to="/admin/settings-role" className="btn">
                      Update
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className={
                  isRoleAdminItem(store.credentials.role_name)
                    ? "col-lg-6 col-md-6 col-sm-12 col-xs-12"
                    : "hide"
                }
              >
                <div className="bg-lightgray mb--20">
                  <h4 className="color-primary t-bold">Branch Code Settings</h4>
                  <p>
                    Manage the Branch Code identifier assigned to different
                    branches.
                  </p>
                  <div className="mb--10">
                    <Link to="/admin/settings-branch-code" className="btn">
                      Update
                    </Link>
                  </div>
                </div>
              </div>
              {/* </div>

            <div className="row"> */}
              <div
                className={
                  isRoleAdminItem(store.credentials.role_name)
                    ? "col-lg-6 col-md-6 col-sm-12 col-xs-12"
                    : "hide"
                }
              >
                <div className="bg-lightgray mb--20">
                  <h4 className="color-primary t-bold">ADP Program Settings</h4>
                  <p>
                    Assign an amount to service value in every payment
                    installment of ADP programs.
                  </p>
                  <div className="mb--10">
                    <Link to="/admin/settings-program-list" className="btn">
                      Update
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className={
                  isRoleCashierItem(store.credentials.role_name)
                    ? "col-lg-6 col-md-6 col-sm-12 col-xs-12"
                    : "hide"
                }
              >
                <div className="bg-lightgray mb--20">
                  <h4 className="color-primary t-bold">
                    Coordinator &amp; Collector Settings
                  </h4>
                  <p>
                    Add account for Coordinator and Collector in the system.
                  </p>
                  <div className="mb--10">
                    <Link to="/admin/settings-coordinator" className="btn">
                      Update
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Settings;
