import React from "react";
import { FaCaretDown, FaCog, FaUserPlus } from "react-icons/fa";
import { setIsAdd, setStartIndex } from "../../../../store/StoreAction";
import { StoreContext } from "../../../../store/StoreContext";
import useFetchDataLoadMore from "../../../custom-hooks/useFetchDataLoadMore";
import Footer from "../../../footer/Footer";
import Header from "../../../header/Header";
import Navigation from "../../../navigation/Navigation";
import LoadMore from "../../../widget/LoadMore";
import ModalError from "../../../widget/ModalError";
import ModalSuccess from "../../../widget/ModalSuccess";
import ModalYesNo from "../../../widget/ModalYesNo";
import BranchCodeTableRows from "./BranchCodeTableRows";
import ModalAddBranch from "./ModalAddBranch";

const SettingsBranchCode = () => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [itemEdit, setItemEdit] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const { loading, handleLoad, totalResult } = useFetchDataLoadMore(
    "/admin/branch/read-branch.php",
    "/admin/branch/read-branch-all.php",
    25
  );

  const handleAdd = () => {
    dispatch(setIsAdd(true));
    // dispatch(setStartIndex(0));
  };

  return (
    <>
      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}
      {store.isConfirm && (
        <ModalYesNo
          edit={{ itemEdit, setItemEdit }}
          url="/admin/branch/archive-branch.php"
        />
      )}

      {store.isAdd && <ModalAddBranch edit={{ itemEdit, setItemEdit }} />}
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="settings" />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">BRANCH CODE</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                <button
                  className="btn right"
                  id="create-new"
                  onClick={handleAdd}
                >
                  <span>
                    <FaUserPlus />
                  </span>
                  <span>Create</span>
                </button>
              </div>
            </div>
          </div>

          <hr />

          <div className="member content-block clearfix mb--20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="content-box bg-highlight bg-lightcolor mb--20">
                  <div className="list mb--40">
                    <table id="" className="" cellSpacing="0" width="100%">
                      <thead className="title-box-light">
                        <tr>
                          <th className="">#</th>
                          <th className="">Branch Code</th>
                          <th className="desktop-view">City</th>
                          <th className="desktop-view">Province</th>
                          <th className="desktop-view">Region</th>
                          <th className="t-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <BranchCodeTableRows
                          store={store}
                          // result={result}
                          dispatch={dispatch}
                          loading={loading}
                          edit={{ setItemEdit }}
                        />
                      </tbody>
                    </table>
                  </div>
                  <LoadMore
                    handleLoad={handleLoad}
                    loading={loading}
                    // result={result}
                    store={store}
                    totalResult={totalResult}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SettingsBranchCode;
