export const setError = (val) => {
  return {
    type: "ERROR",
    payload: val,
  };
};

export const setMessage = (val) => {
  return {
    type: "MESSAGE",
    payload: val,
  };
};

export const setSuccess = (val) => {
  return {
    type: "SUCCESS",
    payload: val,
  };
};

export const setSave = (val) => {
  return {
    type: "SAVE",
    payload: val,
  };
};

export const setViewLedger = (val) => {
  return {
    type: "VIEW_LEDGER",
    payload: val,
  };
};

export const setResultSet = (val) => {
  return {
    type: "RESULT",
    payload: val,
  };
};

export const setIsConfirm = (val) => {
  return {
    type: "CONFIRM",
    payload: val,
  };
};

export const setIsAdd = (val) => {
  return {
    type: "IS_ADD",
    payload: val,
  };
};

export const setIsAdd2 = (val) => {
  return {
    type: "IS_ADD2",
    payload: val,
  };
};

export const setStartIndex = (val) => {
  return {
    type: "START_INDEX",
    payload: val,
  };
};

export const setIsSearch = (val) => {
  return {
    type: "IS_SEARCH",
    payload: val,
  };
};

export const setStatus = (val) => {
  return {
    type: "STATUS",
    payload: val,
  };
};

export const setProgramChange = (val) => {
  return {
    type: "IS_PROGRAM_CHANGE",
    payload: val,
  };
};

export const setCreatePass = (val) => {
  return {
    type: "IS_CREATE_PASS",
    payload: val,
  };
};

export const setCredendtials = (val) => {
  return {
    type: "CREDENTIALS",
    payload: val,
  };
};

export const setIsLogin = (val) => {
  return {
    type: "IS_LOGIN",
    payload: val,
  };
};

export const setAccountSame = (val) => {
  return {
    type: "IS_ACCOUNT_SAME",
    payload: val,
  };
};

export const setSearchUrl = (val) => {
  return {
    type: "SEARCH_URL",
    payload: val,
  };
};
