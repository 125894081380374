import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { setCredendtials } from "../../store/StoreAction";
import { StoreContext } from "../../store/StoreContext";
import fetchApi from "../helpers/fetchApi";
import { devApiUrl } from "../helpers/general-functions";
import Spinner from "../widget/Spinner";

const ProtectedRoute = ({ component: Component }) => {
  const { dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(true);
  const [isAuth, setIsAuth] = React.useState("");
  const fbastoken = JSON.parse(localStorage.getItem("fbastoken"));

  React.useEffect(() => {
    // console.log(typeof fbastoken);
    const fetchLogin = async () => {
      const login = await fetchApi(
        devApiUrl + "/admin/users/read-users-token.php",
        {
          token: fbastoken.token,
          idNumber: fbastoken.data.users_aid,
        }
      );

      // console.log("protected", login);

      if (typeof login === "undefined" || !login.status) {
        setLoading(false);
        setIsAuth("456");
      } else {
        dispatch(setCredendtials(login.data));
        setIsAuth("123");
        setLoading(false);
      }
    };

    if (fbastoken !== null) {
      fetchLogin();
    } else {
      setLoading(false);
      setIsAuth("456");
    }
  }, [dispatch]);

  return loading ? (
    <Spinner />
  ) : isAuth === "123" ? (
    <Route render={(props) => <Component {...props} />} />
  ) : isAuth === "456" ? (
    <Redirect to={{ pathname: "/login" }} />
  ) : (
    <p>API end point error / Page not found.</p>
  );
};

export default ProtectedRoute;
