import {
  setResultBranch,
  setResultSet,
  setTotalServiceValue,
} from "../../store/StoreAction";
import useLoadTransfer from "../custom-hooks/useLoadTransfer";
import { fetchData } from "./fetchData";

// Development api url
// export const devApiUrl = "https://fbas.frontlinebusiness.com.ph/rest";
// export const devApiUrl = "https://fbasapp.com/rest";
export const devApiUrl = "https://devapp.fbasapp.com/rest";

// Production api url
export const prodApiUrl = "https://---/rest";

// Root development domain url
export const rootDevUrl = "https://fbas.frontlinebusiness.com.ph";

// Root production domain url
export const rootProdUrl = "https://---";

// Copyright year
export const copyrightYear = () => {
  return new Date().getFullYear();
};

// storage after login
export function setStorageRoute(jwt, data) {
  localStorage.setItem("fbastoken", JSON.stringify({ token: jwt, data }));
}

// get today date
export const getTodayDate = () => {
  var today = new Date();

  return (
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  );
};

// Date formatting
export const formatDate = (dateVal) => {
  const d = new Date(dateVal);
  const year = d.getFullYear();
  const month = d.getMonth();
  const date = d.getDate();
  // const day = d.getDay();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // return `${days[day]}, ${months[month]} ${date} ${year}`;
  // return `${days[day]}, ${months[month]} ${date}`;
  return `${months[month]} ${date} ${year}`;
  // return `${year} ${months[month]} ${date} `;
};

// subtract months
export const subtractDate = (start) => {
  var nowDate = new Date();
  var nowYear = nowDate.getFullYear();
  var nowMonth = nowDate.getMonth() + 1;
  var nowDay = nowDate.getDate();

  var prevDate = new Date(start);
  var prevYear = prevDate.getFullYear();
  var prevMonth = prevDate.getMonth() + 1;
  var prevDay = prevDate.getDate();

  let numMonths = 12;
  let totalYear = 0;
  let totalMonths = 0;

  if (nowYear == prevYear && nowDay > prevDay) {
    // console.log("past due");
    totalMonths = totalYear * numMonths + (nowMonth - prevMonth);
  }

  if (nowYear == prevYear && nowDay <= prevDay) {
    totalMonths = totalYear * numMonths + (nowMonth - prevMonth) - 1;
  }

  if (nowYear != prevYear && nowDay > prevDay) {
    // console.log("past due + year");
    totalYear = nowYear - prevYear;
    totalMonths = totalYear * numMonths + nowMonth - prevMonth;
  }

  if (nowYear != prevYear && nowDay <= prevDay) {
    totalYear = nowYear - prevYear;
    totalMonths = totalYear * numMonths + (nowMonth - prevMonth) - 1;
  }

  // console.log(totalMonths);
  return totalMonths;
};

// get the url id parameter
export const getUrlParam = (id) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // const param = urlParams.get(id);
  // return param;
  return urlParams;
};

// format the numbers separated by comma
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// accept only numbers
export const handleKey = (e) => {
  if (e.charCode < 48 || e.charCode > 57) {
    e.preventDefault();
  }
};

// fetch with load more functionality
export const doLoadmoreList = (store, dispatch, data) => {
  if (data.data === null || !data.status) {
    dispatch(setResultSet([]));
  } else {
    if (store.startIndex === 0) {
      dispatch(setResultSet(data.data));
    } else {
      dispatch(setResultSet([...store.resultSet, ...data.data]));
    }
  }
};

// fetch all list
export const doAllList = (dispatch, data) => {
  if (data.data === null || !data.status) {
    dispatch(setResultSet([]));
  } else {
    dispatch(setResultSet(data.data));
  }
};

// do load more
export const doLoadmore = (data, setResult) => {
  if (data.data === null || !data.status) {
    setResult([]);
  } else {
    setResult((prevState) => [...prevState, ...data.data]);
  }
};

// do list
export const doList = (data, setResult) => {
  if (data.data === null || !data.status) {
    setResult([]);
  } else {
    setResult(data.data);
  }
};

// get members service value
export const getServiceValue = (count, result, startDate) => {
  // console.log(count);
  let dPrev = addDateByMonth(startDate, count);
  // console.log(dPrev);
  var dP = new Date(dPrev);
  var prevYear = dP.getFullYear();
  var prevMonth = dP.getMonth();
  // var prevDay = dP.getDate();

  let dNow = addDateByMonth(Date.now(), 0);
  var dN = new Date(dNow);
  var nowYear = dN.getFullYear();
  var nowMonth = dN.getMonth();
  // var nowDay = dN.getDate();

  let diff = dN.getTime() - dP.getTime();
  let value = 0;

  // console.log(diff);1
  // console.log(dPrev);

  // if (nowYear >= prevYear && prevMonth <= nowMonth && diff >= 0) {
  // if (diff >= 0 || nowMonth === prevMonth) {
  if (diff >= 0 || (nowMonth === prevMonth && nowYear >= prevYear)) {
    // console.log(count);
    // if (dNow >= dPrev) {
    if (result.length > 0) {
      result.map((res) => {
        if (res.program_id == count) {
          // console.log(count, res.program_value);
          value = res.program_value;
        }
      });
    }
  }
  return value;
};

// compute members remaining balance
export const computeRemainingBalance = (data, total) => {
  let amount = 0;
  let months = parseInt(data.programlist_months);
  let instAmount = parseInt(data.programlist_inst_amount);
  if (instAmount == 300) {
    amount = instAmount * months - total;
  }
  if (instAmount == 500) {
    amount = instAmount * months - total;
  }
  if (instAmount == 750) {
    amount = instAmount * months - total;
  }
  if (instAmount == 1500) {
    amount = instAmount * months - total;
  }
  return amount;
};

// get member status (active, inactive, delinquent, completed)
export const getMemberStatus = (data, inst) => {
  let status = "--";
  // let total = parseInt(data[0].instNum) - 1;
  // let total =
  //   (parseInt(data[0].instNum) - parseInt(data[0].payment_amount)) /
  //   parseInt(data[0].payment_amount);

  // console.log(total, inst);
  let total = (parseInt(data[0].instNum) - parseInt(inst)) / parseInt(inst);
  let created = data[0].payment_created;
  let numDue = subtractDate(created) - total;
  // console.log(numDue, total);

  if (numDue <= 3) {
    status = "Active";
  }

  if (numDue >= 4 && numDue <= 6) {
    status = "Inactive";
  }

  if (numDue >= 7) {
    status = "Delinquent";
  }

  if (total >= 26) {
    status = "Completed";
  }

  return status;
};

// get date minus months
export const subtractDateByMonth = (startDate, index) => {
  let d = new Date(startDate);
  d.setMonth(d.getMonth() - index);
  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
};

// get date add months
export const addDateByMonth = (startDate, index) => {
  let d = new Date(startDate);
  d.setMonth(d.getMonth() + index);
  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
};

// get date format yyyy-mm-dd
export const getDateYmd = (date) => {
  let d = new Date(date);
  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
};

// compute age
export const computeAge = (bdate) => {
  var dob = new Date(bdate);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);

  //extract year from date
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  var age = Math.abs(year - 1970);

  return age;
};

// get total hospital
export const getTotalHospital = (data, hospital) => {
  let value = 0;

  data.map((item) => {
    value += parseInt(item.hospital_amount);
  });
  return numberWithCommas(parseInt(hospital) - parseInt(value));
};

// get total medical
export const getTotalMedical = (data, medical) => {
  let value = 0;
  data.map((item) => {
    value += parseInt(item.medical_amount);
  });
  return numberWithCommas(parseInt(medical) - parseInt(value));
};
