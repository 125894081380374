import React from "react";
import nodata from "../../img/nodata.png";

const NoData = () => {
  return (
    <div className="nodata">
      <img src={nodata} className="nodata-icon" alt="no data" />
      <h3>
        <strong>No Data</strong>
      </h3>
    </div>
  );
};

export default NoData;
