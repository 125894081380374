import React from "react";
import SpinnerButton from "../widget/SpinnerButton";

const LoadMore = ({ handleLoad, loading, store, totalResult }) => {
  if (totalResult === store.resultSet.length) {
    return <div className="t-center">End of records.</div>;
  }

  if (store.resultSet.length > 0) {
    return (
      <>
        <div className="t-center">
          <button
            className="btn"
            id="create-new"
            onClick={handleLoad}
            disabled={loading}
          >
            Load More {loading && <SpinnerButton />}
          </button>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default LoadMore;
