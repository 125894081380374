import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../img/fbas-logo.svg";
import loving from "../../img/loving-logo.svg";
import profile from "../../img/profile.jpg";
import { setIsConfirm, setMessage } from "../../store/StoreAction";
import { StoreContext } from "../../store/StoreContext";
import ModalYesNo from "../widget/ModalYesNo";

const Header = ({ tog }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const { toggle, setToggle } = tog;

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(setIsConfirm(true));
    dispatch(setMessage("Are you sure to logout?"));
  };

  return (
    <header>
      <div className="container">
        <div className="letterhead t-center hide">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3">
              <img src={logo} alt="FBAS header logo" width="80" />{" "}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <h5>Financial Blessings And Support Association, Inc.</h5>
              <h6>Unit 15B Big Ben Complex J.P. Laurel Highway</h6>
              <h6>Mataas na Lupa, Lipa City Batangas</h6>
              <h6>Contact No. 09637812747 / (043-783-5907)</h6>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3">
              <img src={loving} alt="FBAS header logo" width="80" />{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="logo">
        <img src={logo} alt="FBAS header logo" />
      </div>
      <div className="user">
        <ul>
          <li className="username">
            <span className="membername">
              {store.credentials.users_fname +
                " " +
                store.credentials.users_lname}
            </span>
            <span className="role">
              {store.credentials.branch_city}, {store.credentials.role_name}
            </span>
          </li>
          {/* <li>
            <div className="user-dp">
              <img src={profile} alt="Juan Dela Cruz" />
            </div>
          </li> */}
          <li>
            <Link to="/login" onClick={handleLogout}>
              <span id="idSignOut" data-text="Sign Out" className="fas">
                <FaSignOutAlt />
              </span>
            </Link>
          </li>
          <li>
            <span className="nav-trigger fas" onClick={handleToggle}>
              <FaBars />
            </span>
          </li>
        </ul>
      </div>
      {store.isConfirm && <ModalYesNo edit={false} url="" />}
    </header>
  );
};

export default Header;
