import React from "react";
import { FaSearch } from "react-icons/fa";

const MembersSearch = ({
  handleSearch,
  handleChange,
  search,
  store,
  loading,
}) => {
  // const search = React.useRef(null);
  return (
    <>
      <form
        className="mb--20"
        style={{ position: "relative" }}
        onSubmit={(e) => handleSearch(e, search)}
      >
        <input
          type="text"
          ref={search}
          placeholder="Search"
          onChange={handleChange}
        />
        <button type="submit" className="btn-search">
          <span>
            <FaSearch />
          </span>
          <span>Search</span>
        </button>
      </form>
      {store.isSearch && (
        <div className="t-left">
          <p>
            Search Results:{" "}
            <strong>{!loading && store.resultSet.length}</strong>
          </p>
        </div>
      )}
    </>
  );
};

export default MembersSearch;
