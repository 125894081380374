import React from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { setIsConfirm, setMessage } from "../../store/StoreAction";
import { StoreContext } from "../../store/StoreContext";
import { fetchData } from "../helpers/fetchData";
import SpinnerButton from "./SpinnerButton";

const ModalYesNo = ({ edit = false, url }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { itemEdit, setItemEdit } = edit;

  console.log(itemEdit);

  const handleOk = () => {
    if (!edit || !itemEdit) {
      localStorage.removeItem("fbastoken");
      window.location.replace("/login");
    } else {
      fetchData(
        setLoading,
        url,
        { id: itemEdit }, // form data values
        null, // result set data
        "", // success msg
        "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
        dispatch, // context api action
        store, // context api state
        false, // boolean to show success modal
        false // boolean to show load more functionality button
      );
      setItemEdit(false);
    }
  };

  const handleCancel = () => {
    dispatch(setIsConfirm(false));
    dispatch(setMessage(""));
    if (edit || itemEdit) {
      setItemEdit(false);
    }
  };

  return (
    <>
      <div className="modal modal-front">
        <div className="display-center">
          <div className="bg--white">
            <div className="dialogbox color-yellow mb--10">
              <AiFillQuestionCircle />
            </div>
            <h4 className="t-center mb--20">{store.message}</h4>
            <div className="button-container">
              <button className="btn" onClick={handleOk} disabled={loading}>
                OK {loading && <SpinnerButton />}
              </button>
              <button className="btn" onClick={handleCancel}>
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalYesNo;
