export const checkRoleToRedirect = (data, props) => {
  if (
    data.role_name === "Branch Admin" ||
    data.role_name === "Cashier" ||
    data.role_name === "Admin" ||
    data.role_name === "Coordinator" ||
    data.role_name === "Admin"
  ) {
    props.history.push("/admin/members");
    return;
  }

  if (data.role_name === "Manager" || data.role_name === "Sales Area VP") {
    props.history.push("/admin/all-members");
    return;
  }

  if (data.role_name === "Member") {
    props.history.push("/member/ledger");
    return;
  }
};

export const isRoleAdmin = (role) => {
  if (role === "Admin" || role === "Cashier" || role === "Branch Admin") {
    return true;
  } else {
    return false;
  }
};

export const isRoleAdminItem = (role) => {
  if (role === "Admin") {
    return true;
  } else {
    return false;
  }
};

export const isRoleCashier = (role) => {
  if (role === "Branch Admin" || role === "Cashier") {
    return true;
  } else {
    return false;
  }
};

export const isRoleMCVP = (role) => {
  if (
    role === "Branch Admin" ||
    role === "Cashier" ||
    role === "Manager" ||
    role === "Sales Area VP"
  ) {
    return true;
  } else {
    return false;
  }
};

export const isRoleCashierItem = (role) => {
  if (role === "Branch Admin" || role === "Cashier" || role === "Admin") {
    return true;
  } else {
    return false;
  }
};

export const isRoleRegular = (role) => {
  if (role === "Coordinator" || role === "Member") {
    return true;
  } else {
    return false;
  }
};
