import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import logo from "../../../img/fbas-logo.svg";
import ModalSuccess from "../../widget/ModalSuccess";
import ModalError from "../../widget/ModalError";
import { fetchData } from "../../helpers/fetchData";
import { InputText } from "../../helpers/FormInputs";
import SpinnerButton from "../../widget/SpinnerButton";
import { StoreContext } from "../../../store/StoreContext";
import { setCreatePass } from "../../../store/StoreAction";

const LoginForgotPassword = () => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [btnLoading, setLoading] = React.useState(false);

  const initVal = {
    users_email: "",
  };

  const yupSchema = Yup.object({
    users_email: Yup.string().email("Email is invalid").required("Required"),
  });

  return (
    <>
      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}
      <main className="main__login">
        <div className="main__login__wrapper">
          <div className="main__login__form flex-basis-75">
            {/* <div className="welcome__note">
              <img src={logo} alt="" />
              <h3 className="mb--40">
                Financial Blessings And Support Association Philippines
              </h3>
            </div> */}

            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h1 className="color-primary t-bold t-center">
                  Forgot Password
                </h1>
                <p className="t-center mb--60">
                  We will email you an instruction to reset your password.
                </p>
                <Formik
                  initialValues={initVal}
                  validationSchema={yupSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    console.log(values);
                    fetchData(
                      setLoading,
                      "/admin/users/update-users-forgot-pass.php",
                      values, // form data values
                      null, // result set data
                      "Please check your email to continue to recover your account.", // success msg
                      "Email not found.", // additional error msg if needed
                      dispatch, // context api action
                      store, // context api state
                      true, // boolean to show success modal
                      false // boolean to show load more functionality button
                    );
                    dispatch(setCreatePass(true));
                  }}
                >
                  {(props) => {
                    // console.log(props.isSubmitting);

                    return (
                      <Form>
                        <div className="content-box mb--30">
                          <InputText
                            label="Your Email Address"
                            type="email"
                            name="users_email"
                            disabled={btnLoading}
                          />
                        </div>

                        <div className="button-container">
                          <button
                            type="submit"
                            className="btn btn-full"
                            disabled={btnLoading}
                          >
                            SUBMIT {btnLoading && <SpinnerButton />}
                          </button>
                        </div>

                        <a className="go_back" href="/login">
                          Return to Login
                        </a>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
            </div>
          </div>

          <div className="main__login__banner lg-only t-center  flex-basis-25">
            <div className="welcome__note">
              <img src={logo} alt="" width="150" />
              <h3 className="mb--40 color-lightcolor">
                Financial Blessings And Support Association Philippines
              </h3>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginForgotPassword;
