import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { setError, setMessage } from "../../store/StoreAction";
import { StoreContext } from "../../store/StoreContext";

const ModalError = () => {
  const { store, dispatch } = React.useContext(StoreContext);

  const handleOk = () => {
    dispatch(setError(false));
    dispatch(setMessage(""));
  };

  return (
    <>
      <div className="modal" style={{ zIndex: 9999 }}>
        <div className="display-center">
          <div className="bg--white">
            <div className="dialogbox color-red mb--10">
              <AiFillCloseCircle />
            </div>
            <h4 className="t-center mb--20">{store.message}</h4>
            <div className="button-container">
              <button className="btn" onClick={handleOk}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalError;
