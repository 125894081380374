export const getUrl1 = (store) => {
  const role = store.credentials.role_name;

  if (role === "Admin") {
    return "/admin/members/read-members.php";
  }

  if (role === "Cashier" || role === "Branch Admin") {
    return "/admin/members/read-members-by-branch.php";
  }

  if (
    role === "Sales Area VP" ||
    role === "Coordinator" ||
    role === "Manager" ||
    role === "Coordinator" ||
    role === "Director"
  ) {
    return "/admin/members/read-members-by-coordinator.php";
  }
};

export const getUrl2 = (store) => {
  const role = store.credentials.role_name;

  if (role === "Admin") {
    return "/admin/members/read-members-all.php";
  }

  if (role === "Cashier" || role === "Branch Admin") {
    return "/admin/members/read-members-all-by-branch.php";
  }

  if (
    role === "Sales Area VP" ||
    role === "Coordinator" ||
    role === "Manager" ||
    role === "Coordinator" ||
    role === "Director"
  ) {
    return "/admin/members/read-members-all-by-coordinator.php";
  }
};

export const getTypeId = (store) => {
  const role = store.credentials.role_name;

  if (role === "Cashier" || role === "Branch Admin") {
    return store.credentials.users_branch_id;
  }

  if (role === "Coordinator") {
    return store.credentials.users_coor_id;
  }
};
