import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FaClipboardList, FaDatabase, FaSave } from "react-icons/fa";
import { InputSelect, InputText } from "../../helpers/FormInputs";
import SpinnerButton from "../../widget/SpinnerButton";
import fetchApi from "../../helpers/fetchApi";
import { devApiUrl, getUrlParam } from "../../helpers/general-functions";
import { StoreContext } from "../../../store/StoreContext";
import { setMessage, setSuccess } from "../../../store/StoreAction";
import useLoadAll from "../../custom-hooks/useLoadAll";
import usePaymentById from "../../custom-hooks/usePaymentById";

const MembersEditProgramInfo = ({ datas }) => {
  const { dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { result } = useLoadAll("/admin/program-list/read-programlist-all.php");
  const { data } = usePaymentById(
    "/admin/payment/read-payment-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const initVal = {
    members_aid: datas.members_aid,
    members_programlist_id: datas.programlist_aid,
    // members_programlist_id: data.programlist_name,
    members_prog_num: "",
    members_prog_total: datas.members_prog_total,
    members_prog_district: datas.members_prog_district,
    // members_prog_area: data.members_prog_area,
    members_prog_area: datas.branch_city,
    members_prog_cluster: datas.members_prog_cluster,
    members_prog_date: datas.members_prog_date,
  };

  const yupSchema = Yup.object({
    members_programlist_id: Yup.string().required("Required"),
    // members_prog_num: Yup.string().required("Required"),
    // members_prog_total: Yup.string().required("Required"),
    // members_prog_district: Yup.string().required("Required"),
    members_prog_area: Yup.string().required("Required"),
    members_prog_date: Yup.string().required("Required"),
  });

  return (
    <>
      <input
        type="radio"
        name="tabs"
        id="tab-program"
        defaultChecked={getUrlParam().get("tab") === "prog" ? true : false}
      />
      <label htmlFor="tab-program" className="menu-label">
        PROGRAM INFO
      </label>
      <div className="tab" style={{ padding: "30px 10px" }}>
        <Formik
          initialValues={initVal}
          validationSchema={yupSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const member = await fetchApi(
              devApiUrl + "/admin/members/update-members-program.php",
              values
            );

            // console.log(member);
            if (typeof member === "undefined") {
              console.log("undefined");
              setLoading(false);
            }

            if (!member.status) {
              console.log(member.message);
              setLoading(false);
            }

            if (member.status) {
              console.log("Member updated");
              dispatch(setSuccess(true));
              dispatch(setMessage("Successfuly saved."));
              setLoading(false);
              window.location.href =
                "/admin/members-edit?id=" +
                getUrlParam().get("id") +
                "&tab=pay";
            }
          }}
        >
          {(props) => {
            // console.log(props.isSubmitting);

            return (
              <Form>
                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <FaClipboardList />
                      <strong> Program Information</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      {/* <InputText
                        label="Program Type"
                        type="text"
                        name="members_programlist_id"
                        readOnly
                      /> */}
                      <InputSelect
                        label="Program Type"
                        name="members_programlist_id"
                        disabled={data.length > 0 ? true : false}
                        // readOnly
                      >
                        {result.length > 0 ? (
                          <>
                            <option value=""> -- </option>
                            {result.map((item) => (
                              <option
                                key={item.programlist_aid}
                                value={item.programlist_aid}
                              >
                                {item.programlist_name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value=""> -- </option>
                        )}
                      </InputSelect>
                    </div>

                    {/* <div className="content-box">
                      <InputText
                        label="Program Number"
                        type="text"
                        name="members_prog_num"
                        disabled={props.isSubmitting}
                      />
                    </div> */}

                    {/* <div className="content-box">
                      <InputText
                        label="Total Membership"
                        type="text"
                        name="members_prog_total"
                        disabled={props.isSubmitting}
                      />
                    </div> */}
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                {/* <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <FaDatabase />
                      <strong> Data</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div> */}

                <div className="row  mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      <InputText
                        label="District"
                        type="text"
                        name="members_prog_district"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Area"
                        type="text"
                        name="members_prog_area"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Cluster Name"
                        type="text"
                        name="members_prog_cluster"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Date Joined"
                        type="date"
                        name="members_prog_date"
                        disabled={props.isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <button
                      className="btn btn-full"
                      id="create-new"
                      type="submit"
                      disabled={props.isSubmitting}
                    >
                      <span>
                        <FaSave />
                      </span>
                      <span>Save {loading && <SpinnerButton />}</span>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default MembersEditProgramInfo;
