import React from "react";
import { StoreContext } from "../../store/StoreContext";
import { fetchCUD } from "../helpers/fetchCUD";

const useFetchAll = (url) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    fetchCUD(
      setLoading, // Boolean loading values optional
      url,
      { token: "" }, // form data values
      "", // success msg optional
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed optional
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      false // boolean to show load more functionality button
    );
  };

  return {
    loading,
  };
};

export default useFetchAll;
