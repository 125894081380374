import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GrFormClose } from "react-icons/gr";
import { StoreContext } from "../../../store/StoreContext";
import { InputSelect, InputText } from "../../helpers/FormInputs";
import SpinnerButton from "../../widget/SpinnerButton";
import {
  devApiUrl,
  getTodayDate,
  handleKey,
} from "../../helpers/general-functions";
import fetchApi from "../../helpers/fetchApi";
import {
  setSuccess,
  setMessage,
  setError,
  setIsAdd,
} from "../../../store/StoreAction";
import { fetchData } from "../../helpers/fetchData";

const ModalAddPayment = ({ edit, cid, instAmount, payMode }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { itemEdit, setItemEdit } = edit;
  let amount = instAmount;
  let total = 0;

  const initVal = {
    payment_aid: itemEdit ? itemEdit.aid : "",
    payment_cid: cid,
    payment_mode: payMode,
    payment_amount: itemEdit ? itemEdit.amount : instAmount,
    payment_created: itemEdit ? itemEdit.date : getTodayDate(),
    payment_or: itemEdit ? itemEdit.por : "",
  };

  const yupSchema = Yup.object({
    // payment_mode: Yup.string().required("Required"),
    payment_amount: Yup.string().required("Required"),
    payment_created: Yup.string().required("Required"),
    payment_or: Yup.string().required("Required"),
  });

  const handleClose = () => {
    dispatch(setIsAdd(false));
    setItemEdit(false);
  };

  return (
    <>
      <Formik
        initialValues={initVal}
        validationSchema={yupSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // console.log(amount, values.payment_amount);
          if (parseInt(amount) > parseInt(values.payment_amount)) {
            dispatch(setError(true));
            dispatch(setMessage("Insufficient amount."));
            return;
          }

          if (parseInt(amount) < parseInt(values.payment_amount)) {
            let inputAmount = parseInt(values.payment_amount);
            let monthly = parseInt(amount);
            total = inputAmount % monthly;
            if (total !== 0) {
              dispatch(setError(true));
              dispatch(setMessage("Invalid amount for advance payment."));
              return;
            } else {
              total = inputAmount / monthly;
            }
          }

          fetchData(
            setLoading,
            itemEdit
              ? "/admin/payment/update-payment.php"
              : "/admin/payment/create-payment.php",
            { ...values, total }, // form data values
            null, // result set data
            "Payment successful.", // success msg
            "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
            dispatch, // context api action
            store, // context api state
            true, // boolean to show success modal
            false // boolean to show load more functionality button
          );

          setItemEdit(false);
        }}
      >
        {(props) => {
          // console.log(props.isSubmitting);

          return (
            <Form>
              <div className="modal modal-add-employee">
                <div className="display-center">
                  <div className="title-container t-exbold notify-info">
                    <h4 className="modal-title">
                      <span className="color--blue">
                        {itemEdit ? "Edit Payment" : "Add Payment"}{" "}
                      </span>
                    </h4>
                    <span className="btn--close" onClick={handleClose}>
                      <GrFormClose />
                    </span>
                  </div>
                  <div className="bg--white">
                    <div className="content-box">
                      <InputText
                        label="Date"
                        type="date"
                        name="payment_created"
                        // defaultValue={getTodayDate()}
                        disabled={loading}
                      />
                    </div>

                    {/* <div className="content-box">
                      <InputSelect
                        label="Mode of Payment"
                        name="payment_mode"
                        disabled={props.isSubmitting}
                      >
                        <option value=""> -- </option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Semi Annually">Semi Annually</option>
                        <option value="Annually">Annually</option>
                      </InputSelect>
                    </div> */}

                    <div className="content-box">
                      <InputText
                        label="Amount"
                        type="text"
                        name="payment_amount"
                        disabled={loading}
                        onKeyPress={handleKey}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="OR No."
                        type="text"
                        name="payment_or"
                        disabled={loading}
                      />
                    </div>

                    <div className="button-container">
                      <button
                        type="submit"
                        className="btn btn-full"
                        disabled={loading}
                      >
                        {itemEdit ? "SAVE" : "PROCESS PAYMENT"}{" "}
                        {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalAddPayment;
