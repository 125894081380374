import React from "react";
import { StoreContext } from "../../store/StoreContext";
import fetchApi from "../helpers/fetchApi";
import { fetchData } from "../helpers/fetchData";
import { devApiUrl } from "../helpers/general-functions";

const useFetchDataById = (url, param1 = null) => {
  // const { store } = React.useContext(StoreContext);
  // const [data, setData] = React.useState(null);
  // const [loading, setLoading] = React.useState(true);

  // React.useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   setLoading(true);
  //   const result = await fetchApi(devApiUrl + url, id);

  //   // console.log(result);

  //   if (typeof result === "undefined") {
  //     console.log("undefined");
  //     setLoading(false);
  //     return;
  //   }

  //   if (!result.status) {
  //     setLoading(false);
  //     setData(null);

  //     return;
  //   }

  //   if (result.status) {
  //     setData(result.data);
  //     // setData(null);
  //     setLoading(false);
  //   }
  // };

  // return { data, loading };

  const { store, dispatch } = React.useContext(StoreContext);
  const [data, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    fetchData(
      setLoading, // Boolean loading values optional
      url,
      { token: "", id: param1 }, // form data values
      setResult,
      "", // success msg optional
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed optional
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      false // boolean to show load more functionality button
    );
  };

  return {
    loading,
    data,
  };
};

export default useFetchDataById;
