import React from "react";
import Navigation from "../../navigation/Navigation";
import Header from "../../header/Header";
import MembersViewProfile from "./MembersViewProfile";
import MembersViewLedger from "./MembersViewLedger";
import useFetchDataById from "../../custom-hooks/useFetchDataById";
import { getUrlParam } from "../../helpers/general-functions";
import Spinner from "../../widget/Spinner";

const MembersView = () => {
  const [toggle, setToggle] = React.useState(false);
  const { data, loading } = useFetchDataById(
    "/admin/members/read-members-by-id.php",
    getUrlParam().get("id")
  );

  return (
    <>
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="members" />
      <div className="main-content" style={{ position: "relative" }}>
        {loading ? (
          <Spinner />
        ) : data.length > 0 ? (
          <div className="container-fluid">
            <div className="title">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <span className="tab-title">
                    {data[0].members_fname.toUpperCase() +
                      " " +
                      data[0].members_lname.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>

            <hr />

            <div className="member content-block clearfix mb--20">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="content-box bg-highlight mb--20">
                    <div className="tab-menu">
                      {/* <MembersViewProfile data={data[0]} /> */}
                      <MembersViewLedger datas={data[0]} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          "404 page."
        )}
      </div>
    </>
  );
};

export default MembersView;
