import React from "react";
import "../../scss/components/sidenavigation.scss";
import { FaUsers } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";
import logo from "../../img/fbas-logo.svg";
import { MdDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { StoreContext } from "../../store/StoreContext";
import {
  setIsSearch,
  setResultSet,
  setSave,
  setStartIndex,
} from "../../store/StoreAction";
import {
  isRoleAdmin,
  isRoleCashier,
  isRoleMCVP,
} from "../pages/login/login-functions";

const Navigation = ({ tog, menu, search }) => {
  const { store, dispatch } = React.useContext(StoreContext);

  const handlePageIndex = () => {
    if (search !== undefined) {
      search.current.value = "";
    }

    dispatch(setIsSearch(false));
    dispatch(setResultSet([]));
    dispatch(setStartIndex(0));
    store.isSave ? dispatch(setSave(false)) : dispatch(setSave(true));
  };

  return (
    <div className={tog ? "nav-show side-nav" : "side-nav"}>
      {/* toggle the "nav-show" in the element with "side-nav" class to show mobile menu */}
      <div className="logo">
        <img src={logo} alt="" />
        <h3 className="color-lightcolor">
          <strong>FBAS</strong>
        </h3>
      </div>
      <nav>
        <ul>
          {/* add the "active" class to currently open menu */}
          {/* {isRoleAdmin(store.credentials.role_name) && (
            <li id="menu-dashboard" className={menu === "dash" ? "active" : ""}>
              <NavLink to="/">
                <span>
                  <MdDashboard />
                </span>
                <span>Dashboard</span>
              </NavLink>
            </li>
          )} */}
          {isRoleAdmin(store.credentials.role_name) && (
            <li
              id="menu-members"
              className={menu === "members" ? "active" : ""}
            >
              <NavLink to="/admin/members" onClick={handlePageIndex}>
                <span>
                  <FaUsers />
                </span>
                <span>
                  {isRoleCashier(store.credentials.role_name)
                    ? "Branch Members"
                    : "Members"}
                </span>
              </NavLink>
            </li>
          )}

          {isRoleMCVP(store.credentials.role_name) && (
            <li
              id="menu-members"
              className={menu === "all-members" ? "active" : ""}
            >
              <NavLink to="/admin/all-members" onClick={handlePageIndex}>
                <span>
                  <FaUsers />
                </span>
                <span>All Members</span>
              </NavLink>
            </li>
          )}

          {isRoleAdmin(store.credentials.role_name) && (
            <li id="menu-user" className={menu === "settings" ? "active" : ""}>
              <NavLink to="/admin/settings" onClick={handlePageIndex}>
                <span>
                  <FaUserCog />
                </span>
                <span>Settings</span>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
