import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GrFormClose } from "react-icons/gr";
import { setAccountSame, setIsAdd } from "../../../../store/StoreAction";
import { StoreContext } from "../../../../store/StoreContext";
import { InputSelect, InputText } from "../../../helpers/FormInputs";
import SpinnerButton from "../../../widget/SpinnerButton";
import { fetchData } from "../../../helpers/fetchData";
import useFetchBranch from "../../../custom-hooks/useFetchBranch";
import { isRoleRegular } from "../../login/login-functions";

const ModalAddUser = ({ result, edit }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { itemEdit, setItemEdit } = edit;
  const isRegular = isRoleRegular(itemEdit.role_name);

  const { resultData } = useFetchBranch("/admin/branch/read-branch-all.php");

  const initVal = {
    users_aid: itemEdit ? itemEdit.users_aid : "",
    users_fname: itemEdit ? itemEdit.users_fname : "",
    users_lname: itemEdit ? itemEdit.users_lname : "",
    users_role_id: itemEdit ? itemEdit.users_role_id : "",
    users_email: itemEdit ? itemEdit.users_email : "",
    users_branch_id: itemEdit ? itemEdit.users_branch_id : "",
    // users_pass: Math.random().toString(36).substr(7),
  };

  const yupSchema = Yup.object({
    users_fname: Yup.string().required("Required"),
    users_lname: Yup.string().required("Required"),
    users_role_id: Yup.string().required("Required"),
    // users_email: isAccount ? Yup.string().required("Required") : null,
    users_email: itemEdit ? null : Yup.string().required("Required"),
    users_branch_id: Yup.string().required("Required"),
    // users_pass: Yup.string().required("Required"),
  });

  const handleClose = () => {
    dispatch(setIsAdd(false));
    setItemEdit(false);
  };

  return (
    <>
      <div className="modal modal-add-employee">
        <div className="display-center">
          <div className="title-container t-exbold notify-info">
            <h4 className="modal-title">
              <span className="color--blue"> User Info </span>
            </h4>
            <span className="btn--close" onClick={handleClose}>
              <GrFormClose />
            </span>
          </div>
          <div className="bg--white">
            <Formik
              initialValues={initVal}
              validationSchema={yupSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                console.log(values);
                if (values.users_email === store.credentials.users_email) {
                  dispatch(setAccountSame(true));
                }
                fetchData(
                  setLoading,
                  itemEdit
                    ? "/admin/users/update-users-info.php"
                    : "/admin/users/create-users.php",
                  values, // form data values
                  null, // result set data
                  itemEdit
                    ? "Successfuly updated."
                    : "Please check your email to confirm account.", // success msg
                  "Email is already used.", // additional error msg if needed
                  dispatch, // context api action
                  store, // context api state
                  true, // boolean to show success modal
                  false // boolean to show load more functionality button
                );
                setItemEdit(false);
              }}
            >
              {(props) => {
                return (
                  <Form>
                    <div className="content-box">
                      <InputText
                        label="First Name"
                        type="text"
                        name="users_fname"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Last Name"
                        type="text"
                        name="users_lname"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Email Address "
                        type="email"
                        name="users_email"
                        disabled={itemEdit ? true : loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputSelect
                        label={`Branch Code`}
                        name="users_branch_id"
                        disabled={isRegular ? true : loading}
                      >
                        {resultData.length > 0 ? (
                          <>
                            <option value=""> -- </option>
                            {resultData.map((item) => {
                              return (
                                <option
                                  key={item.branch_aid}
                                  value={item.branch_aid}
                                >
                                  {item.branch_code +
                                    " (" +
                                    item.branch_city +
                                    ")"}
                                </option>
                              );
                            })}
                          </>
                        ) : (
                          <option value=""> -- </option>
                        )}
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputSelect
                        label="Role"
                        name="users_role_id"
                        disabled={isRegular ? true : loading}
                      >
                        {result.length > 0 ? (
                          <>
                            <option value=""> -- </option>
                            {result.map((item, key) => {
                              if (
                                item.role_name !== "Member" &&
                                item.role_name !== "Coordinator" &&
                                item.role_name !== "Director"
                              ) {
                                return (
                                  <option key={key} value={item.role_aid}>
                                    {item.role_name}
                                  </option>
                                );
                              }
                            })}
                          </>
                        ) : (
                          <option value=""> -- </option>
                        )}
                      </InputSelect>
                    </div>

                    <div className="button-container">
                      <button type="submit" className="btn" disabled={loading}>
                        {itemEdit ? "SAVE" : "CREATE"}{" "}
                        {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAddUser;
