import React from "react";
import { StoreContext } from "../../store/StoreContext";
import { fetchData } from "../helpers/fetchData";

const useMemberStatus = (url, param1 = null) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [status, setStatus] = React.useState([]);
  // const [rows, setRows] = React.useState([]);
  let rows = [];
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getData();
  }, [store.isSave]);

  const getData = async () => {
    fetchData(
      setLoading, // Boolean loading values optional
      url,
      { token: "", cid: param1 }, // form data values
      setStatus,
      "", // success msg optional
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed optional
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      false // boolean to show load more functionality button
    );
  };

  const getNumInst = (inst) => {
    let total =
      // (parseInt(status[0].instNum) - parseInt(status[0].payment_amount)) /
      (parseInt(status[0].instNum) - parseInt(inst)) / parseInt(inst);
    // parseInt(status[0].payment_amount);

    let totalAll =
      parseInt(status[0].instNum) / parseInt(status[0].payment_amount);

    for (let i = 0; i < totalAll; i++) {
      rows.push({ i: i });
    }

    return total;
  };

  return {
    loading,
    status,
    getNumInst,
    rows,
  };
};

export default useMemberStatus;
