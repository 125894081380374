import React from "react";
import { FaCaretDown, FaCog } from "react-icons/fa";
import { AiFillEdit, AiFillPrinter } from "react-icons/ai";
import { RiExchangeFill } from "react-icons/ri";
import { MdAddCircle, MdTransferWithinAStation } from "react-icons/md";
import NavigationUserMember from "../../navigation/NavigationUserMember";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import NoData from "../../widget/NoData";
import usePaymentById from "../../custom-hooks/usePaymentById";
import useMemberStatus from "../../custom-hooks/useMemberStatus";
import {
  computeAge,
  computeRemainingBalance,
  formatDate,
  getMemberStatus,
  getServiceValue,
  getTotalHospital,
  getTotalMedical,
  numberWithCommas,
} from "../../helpers/general-functions";
import useLoadAll from "../../custom-hooks/useLoadAll";
import useLoadTransfer from "../../custom-hooks/useLoadTransfer";
import MembersViewTransferHistory from "../members/MembersViewTransferHistory";
import useLoadMedical from "../../custom-hooks/useLoadMedical";
import useLoadHospital from "../../custom-hooks/useLoadHospital";
import useLoadChange from "../../custom-hooks/useLoadChange";

const Ledger = ({ datas }) => {
  const [isViewTranfer, setViewTranfer] = React.useState(false);

  const { getPaymentDetails, data } = usePaymentById(
    "/admin/payment/read-payment-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { result } = useLoadAll(
    "/admin/program/read-program-by-type.php",
    datas.programlist_inst_amount
  );

  const { status, getNumInst } = useMemberStatus(
    "/admin/payment/read-payment-by-status.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { transfer } = useLoadTransfer(
    "/admin/transfer/read-transfer-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { medical } = useLoadMedical(
    "/admin/medical/read-medical-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { hospital } = useLoadHospital(
    "/admin/hospital/read-hospital-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  const { change } = useLoadChange(
    "/admin/change/read-change-by-cid.php",
    datas.branch_code + "-" + datas.members_cid
  );

  let totalServiceValue = 0;
  let count = -1;
  let total = 0;

  const computeRemainingSValue = () => {
    let total = 0;
    if (result.length > 0) {
      total =
        Number(result[result.length - 1].program_value) -
        Number(totalServiceValue);
    }

    return total;
  };

  const handleHistoryTransfer = (e) => {
    e.preventDefault();
    setViewTranfer(false);
  };

  return (
    <>
      {isViewTranfer ||
        (transfer.length > 0 && (
          <MembersViewTransferHistory
            isTransfer={{ isViewTranfer, setViewTranfer }}
            cid={datas.branch_code + "-" + datas.members_cid}
          />
        ))}
      <input
        type="radio"
        name="tabs"
        id="tab-ledger"
        // defaultChecked={store.isViewLedger ? true : false}
        defaultChecked
      />
      <label htmlFor="tab-ledger" className="menu-label">
        LEDGER
      </label>

      <div className="tab">
        <div className="content-block ledger">
          <div className="row mb--20">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="content-block-gray">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-child">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              CID No.:{" "}
                            </label>
                            <strong>
                              {datas.branch_code + "-" + datas.members_cid}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Name:&nbsp;
                            </label>
                            <strong>
                              {datas.members_fname + " " + datas.members_lname}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row  col-100">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Address: &nbsp;
                            </label>
                            <strong>
                              {datas.members_address +
                                ", " +
                                datas.members_city +
                                ", " +
                                datas.members_province}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Birth Date: &nbsp;
                            </label>
                            <strong>
                              {datas.members_birthdate
                                ? formatDate(datas.members_birthdate)
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Age: &nbsp;
                            </label>
                            <strong>
                              {computeAge(datas.members_birthdate)}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Contact No: &nbsp;
                            </label>
                            <strong>
                              {datas.members_contact_num
                                ? datas.members_contact_num
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Gender: &nbsp;
                            </label>
                            <strong>
                              {datas.members_gender
                                ? datas.members_gender
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Beneficiary: &nbsp;
                            </label>
                            <strong>
                              {datas.members_ben1_name
                                ? datas.members_ben1_name
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-child">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Reg. Date: &nbsp;
                            </label>
                            <strong>
                              {status.length > 0 &&
                              status[0].payment_created !== null
                                ? formatDate(status[0].payment_created)
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Coordinator: &nbsp;
                            </label>
                            <strong>
                              {datas.coordinator_name
                                ? datas.coordinator_name
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Program Type: &nbsp;
                            </label>
                            <strong>
                              {datas.programlist_inst_amount
                                ? numberWithCommas(
                                    datas.programlist_inst_amount
                                  )
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Collector: &nbsp;
                            </label>
                            <strong>
                              {datas.members_collector ===
                              "Default to Coordinator"
                                ? datas.coordinator_name
                                : datas.members_collector}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Status: &nbsp;
                            </label>
                            <strong>
                              {status.length > 0
                                ? getMemberStatus(
                                    status,
                                    datas.programlist_inst_amount
                                  )
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              No. of Inst. Paid: &nbsp;
                            </label>
                            <strong>
                              {status.length > 0 && status[0].instNum !== null
                                ? getNumInst(datas.programlist_inst_amount)
                                : 0}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Cluster Name: &nbsp;
                            </label>
                            <strong>
                              {datas.members_prog_cluster
                                ? datas.members_prog_cluster
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="content-box  mb--10">
                          <h4 className="t-exbold color-primary">
                            <label className="t-normal color-darkcolor">
                              Area: &nbsp;
                            </label>
                            <strong>
                              {datas.members_prog_area
                                ? datas.members_prog_area
                                : "--"}
                            </strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="ledger-content" className="row mb--30">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="button-container t-right print-button mb--20">
                <button
                  className="btn"
                  onClick={() => {
                    window.print();
                  }}
                >
                  <span>
                    <AiFillPrinter />
                  </span>
                  <span>Print Ledger</span>
                </button>
              </div>
              <div className="member content-block bg-highlight  bg-lightcolor clearfix mb--20">
                <div className="list responsive-table">
                  <table id="" className="" cellSpacing="0" width="100%">
                    <thead className="title-box-light">
                      <tr>
                        <th className="t-center">Date</th>
                        <th className="t-center">O.R. #</th>
                        <th className="t-center">No. of Installment</th>
                        <th className="t-center">Monthly Installment</th>
                        <th className="t-center">Total Payment</th>
                        <th className="t-center">Service Value</th>
                      </tr>
                    </thead>
                    <tbody id="directory">
                      {data.length > 0 &&
                      status.length > 0 &&
                      status[0].instNum !== null ? (
                        getPaymentDetails(datas.programlist_inst_amount).map(
                          (item, key) => {
                            count += 1;
                            total = datas.programlist_inst_amount * count;
                            if (count > 0) {
                              let val = parseInt(
                                getServiceValue(
                                  count,
                                  result,
                                  status[0].payment_created
                                )
                              );
                              if (val !== 0) {
                                totalServiceValue = val;
                              }
                            }
                            return (
                              <tr key={key}>
                                <td className="t-center">
                                  {formatDate(item.date)}
                                </td>
                                <td className="t-center">{item.por}</td>
                                <td className="t-center">
                                  {count === 0 ? "Registration" : count}
                                </td>
                                <td className="t-center">
                                  {numberWithCommas(
                                    datas.programlist_inst_amount
                                  )}
                                </td>
                                <td className="t-center">
                                  {count > 0
                                    ? numberWithCommas(total)
                                    : numberWithCommas(
                                        datas.programlist_inst_amount
                                      )}
                                </td>
                                <td className="t-center">
                                  {count > 0
                                    ? numberWithCommas(
                                        getServiceValue(
                                          // item.payment_amount * count,
                                          // total,
                                          // datas.programlist_inst_amount,
                                          count,
                                          result,
                                          status[0].payment_created
                                        )
                                      )
                                    : 0}
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan="8" style={{ height: "100px" }}>
                            <NoData />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">Membership Remaining Balance:</label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                PHP {numberWithCommas(computeRemainingBalance(datas, total))}
              </h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                <strong className="color-primary">Membership Remarks:</strong>{" "}
                <span className="color-secondary t-bold">
                  {change.length > 0
                    ? `${change[0].change_from} to ${change[0].programlist_name}`
                    : "No change program"}
                </span>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">
                Hospitalization Available Cash Benefits:
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                PHP{" "}
                {hospital.length > 0
                  ? getTotalHospital(
                      hospital,
                      datas.programlist_hospital_amount
                    )
                  : numberWithCommas(datas.programlist_hospital_amount)}
              </h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                <strong className="color-primary">
                  Hospitalization Cash Benefits Remarks:
                </strong>{" "}
                {hospital.length > 0 ? hospital[0].hospital_remarks : ""}{" "}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">
                Medical Reimbursement Available Cash Benefit:
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                PHP{" "}
                {medical.length > 0
                  ? getTotalMedical(medical, datas.programlist_medical_amount)
                  : numberWithCommas(datas.programlist_medical_amount)}
              </h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                <strong className="color-primary">
                  Medical Cash Benefits Remarks:
                </strong>{" "}
                {medical.length > 0 ? medical[0].medical_remarks : ""}{" "}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
              <label className="t-exbold">
                Service Value Remaining Balance:
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4  col-xs-4">
              <h4 className="t-exbold color-primary">
                {/* PHP {numberWithCommas(totalServiceValue)} */}
                PHP {numberWithCommas(computeRemainingSValue())}
              </h4>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-8  col-xs-8">
              <p className="remarks">
                <strong className="color-primary">Transfer History: </strong>
                {transfer.length > 0 ? (
                  <a
                    href="/"
                    className="color-secondary t-bold"
                    onClick={(e) => handleHistoryTransfer(e)}
                  >
                    <u> View... </u>
                  </a>
                ) : (
                  <span className="color-secondary t-bold">None</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ledger;
