import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GrFormClose } from "react-icons/gr";
import { fetchData } from "../../helpers/fetchData";
import { InputText, MyTextArea } from "../../helpers/FormInputs";
import SpinnerButton from "../../widget/SpinnerButton";
import { StoreContext } from "../../../store/StoreContext";
import {
  setError,
  setMessage,
  setProgramChange,
} from "../../../store/StoreAction";

const ModalTransferProgram = ({
  setTransferProgram,
  programType,
  totalInstAmount,
  cid,
  fromName,
  change_aid,
}) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);

  console.log(change_aid);
  const initVal = {
    fromCid: cid,
    transfer_from_name: fromName,
    toCid: "",
    transfer_to_name: "",
    transfer_program: programType,
    transfer_amount: totalInstAmount ? totalInstAmount : 0,
    payment_or: "",
    transfer_remarks: "",
    change_aid,
  };

  const yupSchema = Yup.object({
    toCid: Yup.string().required("Required"),
    payment_or: Yup.string().required("Required"),
    transfer_remarks: Yup.string().required("Required"),
    // transfer_to_name: Yup.string().required("Required"),
  });

  const handleClose = () => {
    setTransferProgram(false);
  };

  return (
    <>
      <Formik
        initialValues={initVal}
        validationSchema={yupSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values);
          if (values.toCid === cid) {
            dispatch(setError(true));
            dispatch(setMessage("CID is the same as the owner CID."));
            return;
          }
          fetchData(
            setLoading,
            "/admin/payment/update-transfer-payment.php",
            values, // form data values
            null, // result set data
            "Program successfuly transfered.", // success msg
            "Invalid CID. Make sure it exist and there is no existing payment associated with it.", // additional error msg if needed
            dispatch, // context api action
            store, // context api state
            true, // boolean to show success modal
            false // boolean to show load more functionality button
          );

          dispatch(setProgramChange(true));
        }}
      >
        {(props) => {
          // console.log(props.handleChange);

          return (
            <Form>
              <div className="modal modal-add-employee">
                <div className="display-center">
                  <div className="title-container t-exbold notify-info">
                    <h4 className="modal-title">
                      <span className="color--blue">Transfer Program</span>
                    </h4>
                    <span className="btn--close" onClick={handleClose}>
                      <GrFormClose />
                    </span>
                  </div>
                  <div className="bg--white">
                    <div className="content-box">
                      <InputText
                        label="Transfer to (CID No.)"
                        type="text"
                        name="toCid"
                      />
                    </div>

                    {/* <div className="content-box">
                      <InputText
                        label="Transfer to (Name)"
                        type="text"
                        name="transfer_to_name"
                      />
                    </div> */}

                    <div className="content-box">
                      <InputText
                        label="Current Program"
                        type="text"
                        name="transfer_program"
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Amount to Pay"
                        type="text"
                        name="transfer_amount"
                        disabled={loading}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="OR No."
                        type="text"
                        name="payment_or"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <MyTextArea
                        label="Remarks"
                        type="text"
                        name="transfer_remarks"
                        disabled={loading}
                      />
                    </div>

                    <div className="button-container">
                      <button
                        type="submit"
                        className="btn btn-full"
                        disabled={loading}
                      >
                        PROCESS {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalTransferProgram;
