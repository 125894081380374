import React from "react";
import fetchApi from "../helpers/fetchApi";
import { devApiUrl } from "../helpers/general-functions";
import { checkRoleToRedirect } from "../pages/login/login-functions";

const useIsLogin = (props) => {
  const [loading, setLoading] = React.useState(false);
  const fbastoken = JSON.parse(localStorage.getItem("fbastoken"));

  React.useEffect(() => {
    setLoading(true);
    const fetchLogin = async () => {
      const login = await fetchApi(
        devApiUrl + "/admin/users/read-users-token.php",
        {
          token: fbastoken.token,
          idNumber: fbastoken.data.users_aid,
        }
      );

      // console.log(login);

      if (typeof login === "undefined" || !login.status) {
        setLoading(false);
      } else {
        setLoading(false);
        checkRoleToRedirect(login.data, props);
      }
    };

    if (fbastoken !== null) {
      fetchLogin();
    } else {
      setLoading(false);
    }
  }, []);

  return { loading };
};

export default useIsLogin;
