import React from "react";
import { FaCaretDown, FaCog } from "react-icons/fa";
import { Link } from "react-router-dom";
import { setResultSet } from "../../../store/StoreAction";
import { formatDate } from "../../helpers/general-functions";
import NoData from "../../widget/NoData";
import Spinner from "../../widget/Spinner";
import { isRoleRegular } from "../login/login-functions";

const MembersSearchTableRows = ({ store, loading, dispatch }) => {
  let count = 0;
  const isRegular = isRoleRegular(store.credentials.role_name);

  return (
    <>
      {loading ? (
        <tr>
          <td colSpan="7" style={{ height: "100px" }}>
            <Spinner />
          </td>
        </tr>
      ) : (
        <>
          {store.resultSet.length > 0 ? (
            store.resultSet.map((item) => {
              count += 1;
              return (
                <tr key={item.members_aid}>
                  <td className="">{count}</td>
                  <td className="">
                    {item.members_fname + " " + item.members_lname}
                  </td>
                  <td className="">
                    {item.branch_code + "-" + item.members_cid}
                  </td>
                  {/* <td className="desktop-view">
                    {item.members_city + ", " + item.members_province}
                  </td> */}
                  <td className="desktop-view">
                    {/* {formatDate(item.members_created)} */}
                    {item.branch_city}
                  </td>
                  {/* <td className="desktop-view">
                    {formatDate(item.members_datetime)}
                  </td> */}
                  <td className="t-right">
                    <div className="dropdown">
                      <span>
                        <FaCog />
                        <FaCaretDown />
                      </span>
                      <div className="dropdown-content">
                        <p>
                          <Link
                            to={`/admin/members-view?id=${item.members_aid}`}
                          >
                            Ledger
                          </Link>
                        </p>
                        {!isRegular && (
                          <p>
                            <Link
                              to={`/admin/members-edit?id=${item.members_aid}`}
                              onClick={() => dispatch(setResultSet([]))}
                            >
                              Edit
                            </Link>
                          </p>
                        )}
                        {/* <p>
                      <a href="/members">Archive</a>
                    </p> */}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7" style={{ height: "100px" }}>
                <NoData />
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default MembersSearchTableRows;
