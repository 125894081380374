import React from "react";
import { FaCaretDown, FaCog, FaSearch, FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Navigation from "../../navigation/Navigation";
import MembersSearch from "../members/MembersSearch";

const SettingsCoordinator = () => {
  const [toggle, setToggle] = React.useState(false);

  return (
    <>
      <Header tog={{ toggle, setToggle }} />
      <Navigation tog={toggle} menu="settings" />
      <div className="main-content">
        <div className="container-fluid">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">COORDINATOR AND COLLECTOR</span>
              </div>
              {/* <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                <button className="btn right" id="create-new">
                  <span>
                    <FaUserPlus />
                  </span>
                  <span>Create</span>
                </button>
              </div> */}
            </div>
          </div>

          <hr />

          <div className="member content-block clearfix mb--20">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="content-box bg-highlight bg-lightcolor mb--20">
                  {/* <div className="search-bar t-center mb--20">
                    <form className="mb--20" style={{ position: "relative" }}>
                      <input
                        type="text"
                        placeholder="Search by first name, last name, CID"
                      />
                      <button type="submit" className="btn-search">
                        <span>
                          <FaSearch />
                        </span>
                        <span>Search</span>
                      </button>
                    </form>
                  </div> */}
                  <div className="list mb--40 responsive-table">
                    <table id="" className="" cellSpacing="0" width="100%">
                      <thead className="title-box-light">
                        <tr>
                          <th className="">#</th>
                          <th className="">Item</th>
                          <th className="t-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="">1</td>
                          <td className="">Coordinator</td>
                          <td className="t-right">
                            <div className="dropdown">
                              <span>
                                <FaCog />
                                <FaCaretDown />
                              </span>
                              <div className="dropdown-content">
                                <p>
                                  <Link to="/admin/settings-coordinator-list">
                                    View
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="">2</td>
                          <td className="">Collector</td>
                          <td className="t-right">
                            <div className="dropdown">
                              <span>
                                <FaCog />
                                <FaCaretDown />
                              </span>
                              <div className="dropdown-content">
                                <p>
                                  <Link to="/admin/settings-collector-list">
                                    View
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SettingsCoordinator;
