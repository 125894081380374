import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GrFormClose } from "react-icons/gr";
import { StoreContext } from "../../../../store/StoreContext";
import { fetchData } from "../../../helpers/fetchData";
import SpinnerButton from "../../../widget/SpinnerButton";
import { setIsAdd } from "../../../../store/StoreAction";
import { InputSelect, InputText } from "../../../helpers/FormInputs";
import useFetchBranch from "../../../custom-hooks/useFetchBranch";
import { isRoleCashier } from "../../login/login-functions";

const ModalAddCoordinator = ({ edit }) => {
  const { resultData } = useFetchBranch("/admin/branch/read-branch-all.php");
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { itemEdit, setItemEdit } = edit;
  const isCashier = isRoleCashier(store.credentials.role_name);

  const initVal = {
    coordinator_id: itemEdit ? itemEdit.coordinator_id : "",
    coordinator_bid: itemEdit
      ? itemEdit.coordinator_bid
      : isCashier
      ? store.credentials.users_branch_id
      : "",
    coordinator_date: itemEdit ? itemEdit.coordinator_date : "",
    coordinator_name: itemEdit ? itemEdit.coordinator_name : "",
    coordinator_contact: itemEdit ? itemEdit.coordinator_contact : "",
    coordinator_email: itemEdit ? itemEdit.coordinator_email : "",
    coordinator_email_old: itemEdit ? itemEdit.coordinator_email : "",
    coordinator_tin: itemEdit ? itemEdit.coordinator_tin : "",
    coordinator_manager: itemEdit ? itemEdit.coordinator_manager : "",
    coordinator_evaluator: itemEdit ? itemEdit.coordinator_evaluator : "",
  };

  const yupSchema = Yup.object({
    coordinator_id: Yup.string().required("Required"),
    coordinator_bid: isCashier ? null : Yup.string().required("Required"),
    coordinator_date: Yup.string().required("Required"),
    coordinator_name: Yup.string().required("Required"),
    coordinator_contact: Yup.string().required("Required"),
    // coordinator_email: Yup.string().required("Required"),
  });

  const handleClose = () => {
    dispatch(setIsAdd(false));
    setItemEdit(false);
  };

  return (
    <>
      <div className="modal modal-add-employee">
        <div className="display-center">
          <div className="title-container t-exbold notify-info">
            <h4 className="modal-title">
              <span className="color--blue">
                {itemEdit ? "Edit Coordinator" : "Add Coordinator"}
              </span>
            </h4>
            <span className="btn--close" onClick={handleClose}>
              <GrFormClose />
            </span>
          </div>
          <div className="bg--white">
            <Formik
              initialValues={initVal}
              validationSchema={yupSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                // console.log(values);
                fetchData(
                  setLoading,
                  itemEdit
                    ? "/admin/coordinator/update-coordinator.php"
                    : "/admin/coordinator/create-coordinator.php",
                  values, // form data values
                  null, // result set data
                  "", // success msg
                  "ID / Email is already used.", // additional error msg if needed
                  dispatch, // context api action
                  store, // context api state
                  false, // boolean to show success modal
                  false // boolean to show load more functionality button
                );
                setItemEdit(false);
              }}
            >
              {(props) => {
                // console.log(props.isSubmitting);

                return (
                  <Form>
                    <div className="content-box">
                      <InputText
                        label="ID"
                        type="text"
                        name="coordinator_id"
                        placeholder="Ex. LIP-1234"
                        disabled={itemEdit || loading ? true : false}
                        readOnly={itemEdit ? true : false}
                      />
                    </div>

                    {!isCashier && (
                      <div className="content-box">
                        <InputSelect
                          label="Branch"
                          name="coordinator_bid"
                          disabled={loading}
                        >
                          {resultData.length > 0 ? (
                            <>
                              <option value=""> -- </option>
                              {resultData.map((item) => (
                                <option
                                  key={item.branch_aid}
                                  value={item.branch_aid}
                                >
                                  {item.branch_city} - ({item.branch_code})
                                </option>
                              ))}
                            </>
                          ) : (
                            <option value=""> -- </option>
                          )}
                        </InputSelect>
                      </div>
                    )}

                    <div className="content-box">
                      <InputText
                        label="Effective Date"
                        type="date"
                        name="coordinator_date"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Name"
                        type="text"
                        name="coordinator_name"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Mobile No."
                        type="text"
                        name="coordinator_contact"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Email Address "
                        type="email"
                        name="coordinator_email"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="TIN"
                        type="text"
                        name="coordinator_tin"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Program Manager"
                        type="text"
                        name="coordinator_manager"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Evaluated By"
                        type="text"
                        name="coordinator_evaluator"
                        disabled={loading}
                      />
                    </div>

                    <div className="button-container">
                      <button type="submit" className="btn" disabled={loading}>
                        {itemEdit ? "SAVE" : "ADD"}{" "}
                        {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAddCoordinator;
