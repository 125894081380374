import React from "react";
import {
  setIsSearch,
  setResultSet,
  setSave,
  setStartIndex,
} from "../../store/StoreAction";
import { StoreContext } from "../../store/StoreContext";
import fetchApi from "../helpers/fetchApi";
import { fetchCUD } from "../helpers/fetchCUD";
import { devApiUrl } from "../helpers/general-functions";

const useFetchDataLoadMore = (
  url,
  url2,
  perPage = 2,
  param1 = null,
  url3 = null,
  param2 = null
) => {
  const { store, dispatch } = React.useContext(StoreContext);
  // const perPage = 10;
  // const [result, setResult] = React.useState([]);
  // const [totalResult, setTotalResult] = React.useState(10);
  const [totalResult, setTotalResult] = React.useState(perPage);
  const [loading, setLoading] = React.useState(false);
  // const [isSearch, setIsSearch] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, [store.isSave]);

  const fetchData = async () => {
    fetchCUD(
      setLoading, // Boolean loading values optional
      url,
      { token: "", start: store.startIndex, total: perPage, type: param1 }, // form data values
      "", // success msg optional
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed optional
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      true // boolean to show load more functionality button
    );

    // setStartIndex((prevState) => prevState + perPage);
    dispatch(setStartIndex(store.startIndex + perPage));

    // get total result of data
    const result = await fetchApi(devApiUrl + url2, {
      token: "",
      type: param1,
    });

    // console.log(result);

    if (typeof result === "undefined") {
      console.log("undefined");
      return;
    }
    if (!result.status) {
      setTotalResult([]);
      return;
    }
    if (result.status) {
      setTotalResult(result.data.length);
    }
  };

  const handleLoad = () => {
    fetchCUD(
      setLoading, // Boolean loading values optional
      url,
      { token: "", start: store.startIndex, total: perPage, type: param1 }, // form data values
      "", // success msg optional
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed optional
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      true // boolean to show load more functionality button
    );
    // setStartIndex((prevState) => prevState + perPage);
    dispatch(setStartIndex(store.startIndex + perPage));
  };

  const handleSearch = async (e, search) => {
    e.preventDefault();
    // setIsSearch(true);

    let val = search.current.value;
    if (val === "") return;
    dispatch(setIsSearch(true));
    fetchCUD(
      setLoading,
      // "/admin/members/read-members-search.php",
      url3,
      { token: "", search: val, type: param2 }, // form data values
      "", // success msg
      "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
      dispatch, // context api action
      store, // context api state
      false, // boolean to show success modal
      false // boolean to show load more functionality button
    );
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      dispatch(setIsSearch(false));
      dispatch(setStartIndex(0));
      store.isSave ? dispatch(setSave(false)) : dispatch(setSave(true));
    }
  };

  return {
    loading,
    handleLoad,
    // result,
    totalResult,
    handleSearch,
    // isSearch,
    handleChange,
  };
};

export default useFetchDataLoadMore;
