import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import logo from "../../../img/fbas-logo.svg";
import { StoreContext } from "../../../store/StoreContext";
import { fetchData } from "../../helpers/fetchData";
import ModalSuccess from "../../widget/ModalSuccess";
import ModalError from "../../widget/ModalError";
import { InputText } from "../../helpers/FormInputs";
import SpinnerButton from "../../widget/SpinnerButton";
import { setIsLogin } from "../../../store/StoreAction";
import useIsLogin from "../../custom-hooks/useIsLogin";
import Spinner from "../../widget/Spinner";

const Login = (props) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [btnLoading, setLoading] = React.useState(false);
  const { loading } = useIsLogin(props);
  const fbastoken = JSON.parse(localStorage.getItem("fbastoken"));

  const initVal = {
    users_email: "",
    users_pass: "",
  };

  const yupSchema = Yup.object({
    users_email: Yup.string().email("Email is invalid").required("Required"),
    users_pass: Yup.string().required("Required"),
  });

  React.useEffect(() => {
    if (fbastoken === null) {
      console.log("Login", store.isLogin);
      dispatch(setIsLogin(true));
    } else {
      console.log("Login", store.isLogin);
      dispatch(setIsLogin(false));
    }
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <main className="main__login">
          <div className="main__login__wrapper">
            <div className="main__login__form flex-basis-40">
              <div className="welcome__note">
                <img src={logo} alt="" />
                <h3>Financial Blessings And Support Association Philippines</h3>
                <p>Please enter your login details</p>
              </div>

              <Formik
                initialValues={initVal}
                validationSchema={yupSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  console.log(values);
                  fetchData(
                    setLoading,
                    "/admin/users/read-users-login.php",
                    values, // form data values
                    null, // result set data
                    "Access granted.", // success msg
                    "Access denied.", // additional error msg if needed
                    dispatch, // context api action
                    store, // context api state
                    false, // boolean to show success modal
                    false, // boolean to show load more functionality button
                    props // props optional
                  );
                }}
              >
                {(props) => {
                  // console.log(props.isSubmitting);
                  // if (!isAccount) {
                  //   props.values.users_email = "";
                  // }
                  return (
                    <Form>
                      <div className="mb--20">
                        <InputText
                          label="Email Address"
                          type="email"
                          name="users_email"
                          disabled={btnLoading}
                        />
                      </div>
                      <div className="mb--20">
                        <InputText
                          label="Password"
                          type="password"
                          name="users_pass"
                          disabled={btnLoading}
                        />
                      </div>
                      <div className="option">
                        <a href="/member-signup-usertype">Signup</a>
                        <a href="/forgot-password">Forgot Password</a>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-full"
                        disabled={btnLoading}
                      >
                        LOGIN {btnLoading && <SpinnerButton />}
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </div>

            <div className="main__login__banner lg-only t-center flex-basis-60">
              <div className="quote">
                <h2>
                  We aim to uplift Flipino people's economic standard through
                  <span> livelihood </span> support, <span> moral </span>{" "}
                  recovery and <span> damayan </span> program especially in
                  times of illnesses, accidents and death.
                </h2>
              </div>
            </div>
          </div>
        </main>
      )}
      {store.success && <ModalSuccess />}
      {store.error && <ModalError />}
    </>
  );
};

export default Login;
