import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FaSave, FaSearch, FaUser } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { MdContactPhone } from "react-icons/md";
import { InputSelect, InputText } from "../../helpers/FormInputs";
import fetchApi from "../../helpers/fetchApi";
import {
  devApiUrl,
  getUrlParam,
  handleKey,
} from "../../helpers/general-functions";
import SpinnerButton from "../../widget/SpinnerButton";
import { StoreContext } from "../../../store/StoreContext";
import { setError, setMessage, setSuccess } from "../../../store/StoreAction";
import useFetchAll from "../../custom-hooks/useFetchAll";
import MembersSearchCity from "./MembersSearchCity";
import useFetchBranch from "../../custom-hooks/useFetchBranch";
import useLoadCoordinators from "../../custom-hooks/useLoadCoordinators";
import useLoadCollector from "../../custom-hooks/useLoadCollector";

const MembersEditPersonal = ({ data }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const [city, setCity] = React.useState(null);
  const [coor, setCoor] = React.useState(false);
  const [coorName, setCoorName] = React.useState("");
  const { resultData } = useFetchBranch("/admin/branch/read-branch-all.php");
  useFetchAll("/admin/branch/read-branch-all.php");
  const { coordinators } = useLoadCoordinators(
    "/admin/coordinator/read-coordinator-by-branch.php",
    data.branch_aid
  );
  const { collector } = useLoadCollector(
    "/admin/collector/read-collector-by-branch.php",
    data.branch_aid
  );

  const initVal = {
    // members_status: data.members_status,
    members_branch_id: data.members_branch_id + "-" + data.branch_code,
    oldMembers_branch_id: data.members_branch_id,
    cid: data.members_cid,
    oldBcode: data.branch_code,
    oldCid: data.members_cid,
    members_aid: data.members_aid,
    members_fname: data.members_fname,
    members_mname: data.members_mname,
    members_lname: data.members_lname,
    members_birthdate: data.members_birthdate,
    members_birthplace: data.members_birthplace,
    members_gender: data.members_gender,
    members_civil_status: data.members_civil_status,
    members_height: data.members_height,
    members_weight: data.members_weight,

    members_address: data.members_address,
    members_city: data.members_city,
    members_province: data.members_province,
    members_country: data.members_country,
    members_postal: data.members_postal,
    members_contact_num: data.members_contact_num,
    members_email: data.members_email,
    members_email_old: data.members_email,
    members_agent: data.members_agent,
    members_collector: data.members_collector
      ? data.members_collector
      : "Default to Coordinator",
    members_region: data.branch_region,
    members_branch: data.branch_city,
  };

  const yupSchema = Yup.object({
    // members_status: Yup.string().required("Required"),
    members_branch_id: Yup.string().required("Required"),
    cid: Yup.string().required("Required"),
    members_fname: Yup.string().required("Required"),
    members_mname: Yup.string().required("Required"),
    members_lname: Yup.string().required("Required"),
    members_birthdate: Yup.string().required("Required"),
    // members_birthplace: Yup.string().required("Required"),
    members_gender: Yup.string().required("Required"),
    // members_civil_status: Yup.string().required("Required"),
    // members_height: Yup.string().required("Required"),
    // members_weight: Yup.string().required("Required"),
    members_address: Yup.string().required("Required"),
    members_city: Yup.string().required("Required"),
    members_province: Yup.string().required("Required"),
    members_country: Yup.string().required("Required"),
    members_postal: Yup.string().required("Required"),
    members_contact_num: Yup.string().required("Required"),
    members_agent: Yup.string().required("Required"),
    members_collector: Yup.string().required("Required"),
    members_region: Yup.string().required("Required"),
    members_branch: Yup.string().required("Required"),
  });

  // const handleCoor = (e) => {
  //   console.log(e.target.value);
  //   setCoor(true);
  //   setCoorName(e.target.value);
  // };

  return (
    <>
      <input
        type="radio"
        name="tabs"
        id="tab-personal"
        defaultChecked={getUrlParam().get("tab") === "bene" ? false : true}
      />
      <label htmlFor="tab-personal" className="menu-label">
        PERSONAL INFO
      </label>

      <div className="tab" style={{ padding: "30px 10px" }}>
        <Formik
          initialValues={initVal}
          validationSchema={yupSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log(values);
            setLoading(true);
            const member = await fetchApi(
              devApiUrl + "/admin/members/update-members-personal.php",
              {
                // oldMemberCid: data.members_cid,
                // oldMemberBranchCode: data.members_branch_code,
                ...values,
              }
            );

            console.log(member);
            if (typeof member === "undefined") {
              console.log("undefined");
              setLoading(false);
            }

            if (!member.status) {
              console.log(member.message);
              dispatch(setError(true));
              dispatch(setMessage(`${values.cid} - ${member.message}`));
              setLoading(false);
            }

            if (member.status) {
              console.log("Member updated");
              dispatch(setSuccess(true));
              dispatch(setMessage("Successfuly saved."));
              setLoading(false);
              window.location.href =
                "/admin/members-edit?id=" +
                getUrlParam().get("id") +
                "&tab=bene";
            }
          }}
        >
          {(props) => {
            // console.log(props.isSubmitting);
            // props.values.members_province = prov;
            if (city) {
              props.values.members_city = city.city_name;
              props.values.members_province = city.city_province;
              props.values.members_postal = city.city_postal;
              props.values.members_country = city.city_country;
            }

            // if (coor) {
            //   props.values.members_agent = coorName;
            // }

            return (
              <Form>
                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <FaUser />
                      <strong> Personal Information</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    {/* <div className="content-box">
                      <InputSelect label="Member Status" name="members_status">
                        <option value=""> -- </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="delinquent">Delinquent</option>
                        <option value="completed">Completed</option>
                      </InputSelect>
                    </div> */}
                    <div className="content-box">
                      <InputSelect
                        label={`Branch Code`}
                        name="members_branch_id"
                        disabled={props.isSubmitting}
                      >
                        {resultData.length > 0 ? (
                          <>
                            <option value=""> -- </option>
                            {resultData.map((item) => (
                              <option
                                key={item.branch_aid}
                                value={item.branch_aid + "-" + item.branch_code}
                              >
                                {item.branch_code}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value=""> -- </option>
                        )}
                      </InputSelect>
                    </div>
                    <div className="content-box">
                      <InputText
                        label="ID"
                        type="text"
                        name="cid"
                        onKeyPress={handleKey}
                        // disabled={true}
                        // style={{ textTransform: "uppercase" }}
                      />
                    </div>
                    <div className="content-box">
                      <InputText
                        label="First Name"
                        type="text"
                        name="members_fname"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Middle Name"
                        type="text"
                        name="members_mname"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Last Name"
                        type="text"
                        name="members_lname"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Birth Date"
                        type="date"
                        name="members_birthdate"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Birth Place"
                        type="text"
                        name="members_birthplace"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputSelect label="Gender" name="members_gender">
                        <option value=""> -- </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputSelect
                        label="Marital Status"
                        name="members_civil_status"
                      >
                        <option value=""> -- </option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Widowed">Widowed</option>
                        <option value="Separated">Separated</option>
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Height"
                        type="text"
                        name="members_height"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Weight"
                        type="text"
                        name="members_weight"
                        disabled={props.isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <HiLocationMarker />
                      <strong>Address</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row  mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      <InputText
                        label="House No./Village/Street/Barangay"
                        type="text"
                        name="members_address"
                        disabled={props.isSubmitting}
                        autoComplete="false"
                      />
                    </div>

                    <MembersSearchCity setCity={setCity} />

                    <div className="content-box">
                      <InputText
                        label="City"
                        type="text"
                        name="members_city"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Province"
                        type="text"
                        name="members_province"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Country"
                        type="text"
                        name="members_country"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Postal Code"
                        type="text"
                        name="members_postal"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <MdContactPhone />
                      <strong>Contact / Region / Branch</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      <InputText
                        label="Contact No."
                        type="text"
                        name="members_contact_num"
                        disabled={props.isSubmitting}
                      />
                    </div>
                    <div className="content-box">
                      <InputText
                        label="Email"
                        type="email"
                        name="members_email"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputSelect
                        label="Agent / Coordinator"
                        name="members_agent"
                        disabled={props.isSubmitting}
                        // handleCoor={(e) => handleCoor(e)}
                      >
                        {coordinators.length > 0 ? (
                          <>
                            <option value=""> -- </option>
                            {coordinators.map((item) => (
                              <option
                                key={item.coordinator_aid}
                                value={item.coordinator_id}
                              >
                                {item.coordinator_name} - ({item.branch_city})
                              </option>
                            ))}
                          </>
                        ) : (
                          <>
                            <option value=""> No entry </option>
                          </>
                        )}
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputSelect
                        label="Collector"
                        name="members_collector"
                        disabled={props.isSubmitting}
                      >
                        {collector.length > 0 ? (
                          <>
                            <option value="Default to Coordinator">
                              Default to Coordinator
                            </option>
                            {collector.map((item) => (
                              <option
                                key={item.collector_aid}
                                value={item.collector_id}
                              >
                                {item.collector_name} - ({item.branch_city})
                              </option>
                            ))}
                          </>
                        ) : (
                          <>
                            <option value="Default to Coordinator">
                              Default to Coordinator
                            </option>
                            <option value=""> Not found </option>
                          </>
                        )}
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Region"
                        type="text"
                        name="members_region"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Branch"
                        type="text"
                        name="members_branch"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                      {/* <InputSelect
                        label="Branch"
                        name="members_branch"
                        disabled={props.isSubmitting}
                      >
                        {store.resultSet.length > 0 ? (
                          <>
                            <option value=""> -- </option>
                            {store.resultSet.map((item) => (
                              <option
                                key={item.branch_aid}
                                value={item.branch_city}
                              >
                                {item.branch_city}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option value=""> -- </option>
                        )}
                      </InputSelect> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <button
                      className="btn btn-full"
                      id="create-new"
                      type="submit"
                      disabled={props.isSubmitting}
                    >
                      <span>
                        <FaSave />
                      </span>
                      <span>Save {loading && <SpinnerButton />}</span>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default MembersEditPersonal;
