import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GrFormClose } from "react-icons/gr";
import { setIsAdd } from "../../../../store/StoreAction";
import { StoreContext } from "../../../../store/StoreContext";
import { fetchData } from "../../../helpers/fetchData";
import { InputText } from "../../../helpers/FormInputs";
import { handleKey } from "../../../helpers/general-functions";
import SpinnerButton from "../../../widget/SpinnerButton";

const ModalAddADPProgram = ({ edit }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { itemEdit, setItemEdit } = edit;

  const initVal = {
    programlist_aid: itemEdit ? itemEdit.programlist_aid : "",
    programlist_name: itemEdit ? itemEdit.programlist_name : "",
    programlist_months: itemEdit ? itemEdit.programlist_months : "",
    programlist_inst_amount: itemEdit ? itemEdit.programlist_inst_amount : "",
    programlist_hospital_amount: itemEdit
      ? itemEdit.programlist_hospital_amount
      : "",
    programlist_medical_amount: itemEdit
      ? itemEdit.programlist_medical_amount
      : "",
    programlist_member_amount: itemEdit
      ? itemEdit.programlist_member_amount
      : "",
  };

  const yupSchema = Yup.object({
    programlist_name: Yup.string().required("Required"),
    programlist_months: Yup.string().required("Required"),
    programlist_inst_amount: Yup.string().required("Required"),
    programlist_hospital_amount: Yup.string().required("Required"),
    programlist_medical_amount: Yup.string().required("Required"),
    programlist_member_amount: Yup.string().required("Required"),
  });

  const handleClose = () => {
    dispatch(setIsAdd(false));
    setItemEdit(false);
  };

  return (
    <>
      <div className="modal modal-add-employee">
        <div className="display-center">
          <div className="title-container t-exbold notify-info">
            <h4 className="modal-title">
              <span className="color--blue">
                {" "}
                {itemEdit ? "Edit Program" : "Add Program"}
              </span>
            </h4>
            <span className="btn--close" onClick={handleClose}>
              <GrFormClose />
            </span>
          </div>
          <div className="bg--white">
            <Formik
              initialValues={initVal}
              validationSchema={yupSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log(values);
                fetchData(
                  setLoading,
                  itemEdit
                    ? "/admin/program-list/update-programlist.php"
                    : "/admin/program-list/create-programlist.php",
                  values, // form data values
                  null, // result set data
                  "", // success msg
                  "Server connection error. Please contact FBAS technical support.", // additional error msg if needed
                  dispatch, // context api action
                  store, // context api state
                  false, // boolean to show success modal
                  false // boolean to show load more functionality button
                );
                setItemEdit(false);
              }}
            >
              {(props) => {
                // console.log(props.isSubmitting);

                return (
                  <Form>
                    <div className="content-box">
                      <InputText
                        label="Program Name"
                        type="text"
                        name="programlist_name"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="No. of Months"
                        type="text"
                        name="programlist_months"
                        disabled={loading}
                        onKeyPress={handleKey}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Monthly Installment"
                        type="text"
                        name="programlist_inst_amount"
                        disabled={loading}
                        onKeyPress={handleKey}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Hospitalization Benefits"
                        type="text"
                        name="programlist_hospital_amount"
                        disabled={loading}
                        onKeyPress={handleKey}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Medical Benefits"
                        type="text"
                        name="programlist_medical_amount"
                        disabled={loading}
                        onKeyPress={handleKey}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Total Membership"
                        type="text"
                        name="programlist_member_amount"
                        disabled={loading}
                        onKeyPress={handleKey}
                      />
                    </div>

                    <div className="button-container">
                      <button type="submit" className="btn" disabled={loading}>
                        {itemEdit ? "SAVE" : "ADD"}{" "}
                        {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAddADPProgram;
