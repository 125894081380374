import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  setAccountSame,
  setMessage,
  setSuccess,
} from "../../store/StoreAction";
import { StoreContext } from "../../store/StoreContext";

const ModalSuccess = ({ props }) => {
  const { store, dispatch } = React.useContext(StoreContext);

  const handleOk = () => {
    dispatch(setSuccess(false));
    dispatch(setMessage(""));

    if (store.isProgramChange) {
      window.location.reload();
    }

    if (store.isCreatePass) {
      // props.history.push("/login");
      window.location.href = "/login";
    }

    if (store.isAccountSame) {
      localStorage.removeItem("fbastoken");
      dispatch(setAccountSame(false));
      // props.history.push("/login");
      window.location.href = "/login";
    }
  };

  return (
    <>
      <div className="modal modal-front">
        <div className="display-center">
          <div className="bg--white">
            <div className="dialogbox color-green mb--10">
              <AiFillCheckCircle />
            </div>
            <h4 className="t-center mb--20">{store.message}</h4>
            <div className="button-container">
              <button className="btn" onClick={handleOk}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalSuccess;
