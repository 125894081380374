import React from "react";
import logo from "../../../img/fbas-logo.svg";

const LoginChangeDefaultPassword = () => {
  return (
    <>
      <main className="main__login">
        <div className="main__login__wrapper">
          <div className="main__login__form flex-basis-75">
            {/* <div className="welcome__note">
              <img src={logo} alt="" />
              <h3 className="mb--40">
                Financial Blessings And Support Association Philippines
              </h3>
            </div> */}

            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h1 className="color-primary t-bold t-center">
                  Change Default Password
                </h1>
                <p className="t-center mb--60">Enter a new password.</p>
                <form action="#">
                  <div className="content-box mb--30">
                    <label>Create New Password</label>
                    <input
                      type="text"
                      name="newPassword"
                      value=""
                      className="error-show"
                    />
                    <span className="error-msg error-show">Required</span>
                  </div>

                  <a href="/" className="btn btn-full">
                    SUBMIT
                  </a>
                </form>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12"> </div>
            </div>
          </div>

          <div className="main__login__banner lg-only t-center  flex-basis-25">
            <div className="welcome__note">
              <img src={logo} alt="" width="150" />
              <h3 className="mb--40 color-lightcolor">
                Financial Blessings And Support Association Philippines
              </h3>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginChangeDefaultPassword;
