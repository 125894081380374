import React from "react";
import { FaCaretDown, FaCog, FaUserPlus } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import {
  setIsAdd2,
  setIsConfirm,
  setMessage,
  setStatus,
} from "../../../store/StoreAction";
import { StoreContext } from "../../../store/StoreContext";
import useLoadAll from "../../custom-hooks/useLoadAll";
import { formatDate, numberWithCommas } from "../../helpers/general-functions";
import NoData from "../../widget/NoData";
import Spinner from "../../widget/Spinner";

const ModalViewAddHospital = ({
  setViewHospital,
  cid,
  edit,
  setAddHospital,
}) => {
  const { dispatch } = React.useContext(StoreContext);
  const { itemEdit, setItemEdit } = edit;
  const { loading, result } = useLoadAll(
    "/admin/hospital/read-hospital-by-cid.php",
    cid
  );
  let count = 0;

  const handleAdd = () => {
    setAddHospital(true);
    dispatch(setStatus(true));
    dispatch(setIsAdd2(true));
  };

  const handleClose = () => {
    setViewHospital(false);
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setAddHospital(true);
    dispatch(setStatus(true));
    dispatch(setIsAdd2(true));
    setItemEdit(item);
  };

  const handleDelete = (e, item) => {
    e.preventDefault();
    setItemEdit(item.hospital_aid);
    dispatch(setIsConfirm(true));
    dispatch(setMessage("Are you sure to delete?"));
  };

  return (
    <>
      <div className="modal modal-add-employee">
        <div className="display-center display-wide">
          <div className="title-container t-exbold notify-info">
            <h4 className="modal-title">
              <span className="color--blue">Hospitalization</span>
            </h4>
            <span className="btn--close" onClick={handleClose}>
              <GrFormClose />
            </span>
          </div>
          <div className="bg--white">
            <div className="content-box">
              <div className="button-container justify-right">
                <button
                  className="btn right mb--10"
                  id="create-new"
                  onClick={handleAdd}
                >
                  <span>
                    <FaUserPlus />
                  </span>
                  <span>Create</span>
                </button>
              </div>
              <div className="list responsive-table">
                <table id="" className="" cellSpacing="0" width="100%">
                  <thead className="title-box-light">
                    <tr>
                      <th className="t-center" width="20">
                        #
                      </th>
                      <th className="t-center" width="150">
                        Date
                      </th>
                      <th className="t-center">Amount</th>
                      <th className="t-center" width="200">
                        Remarks
                      </th>

                      <th className="t-center" width="150">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody id="directory">
                    {result.length > 0 ? (
                      result.map((item, key) => {
                        count += 1;
                        return (
                          <tr key={key}>
                            <td className="t-center">{count}</td>
                            <td className="t-center">
                              {formatDate(item.hospital_datetime)}
                            </td>
                            <td className="t-center">
                              {numberWithCommas(item.hospital_amount)}
                            </td>
                            <td className="t-center">
                              {item.hospital_remarks}
                            </td>

                            <td className="t-center">
                              <div className="dropdown">
                                <span>
                                  <FaCog />
                                  <FaCaretDown />
                                </span>
                                <div className="dropdown-content">
                                  <p>
                                    <Link
                                      to="/settings"
                                      onClick={(e) => handleEdit(e, item)}
                                    >
                                      Edit
                                    </Link>
                                  </p>
                                  <p>
                                    <Link
                                      to="/settings"
                                      onClick={(e) => handleDelete(e, item)}
                                    >
                                      Delete
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="8" style={{ height: "100px" }}>
                          {loading && <Spinner />}
                          <NoData />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalViewAddHospital;
