import React from "react";
import fetchApi from "../helpers/fetchApi";
import { devApiUrl } from "../helpers/general-functions";

const useFetchBranch = (url, param1 = null) => {
  const [loading, setLoading] = React.useState(false);
  const [resultData, setResultData] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // get total result of data
    const result = await fetchApi(devApiUrl + url, { token: "", type: param1 });

    if (typeof result === "undefined") {
      console.log("undefined");
      return;
    }
    if (!result.status) {
      setResultData([]);
      return;
    }
    if (result.status) {
      setResultData(result.data);
    }
  };

  return {
    loading,
    resultData,
  };
};

export default useFetchBranch;
