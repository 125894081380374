import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FaSave } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { InputSelect, InputText } from "../../helpers/FormInputs";
import SpinnerButton from "../../widget/SpinnerButton";
import fetchApi from "../../helpers/fetchApi";
import {
  devApiUrl,
  getUrlParam,
  handleKey,
} from "../../helpers/general-functions";
import { StoreContext } from "../../../store/StoreContext";
import { setMessage, setSuccess } from "../../../store/StoreAction";
import MembersPayment from "./MembersPayment";
import MembersSearchCity from "./MembersSearchCity";
import MembersCashBenefits from "./MembersCashBenefits";
import useLoadAll from "../../custom-hooks/useLoadAll";
import { Link } from "react-router-dom";

const MembersEditPayment = ({ data, props }) => {
  const { dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const [city, setCity] = React.useState(null);
  const { result } = useLoadAll("/admin/program-list/read-programlist-all.php");

  const initVal = {
    members_aid: data.members_aid,
    members_pay_mode: data.members_pay_mode,
    members_pay_amount: data.programlist_inst_amount,
    // members_hospital_amount: data.members_hospital_amount,
    // members_hospital_remarks: data.members_hospital_remarks,
    // members_medreim_amount: data.members_medreim_amount,
    // members_medreim_remarks: data.members_medreim_remarks,
    // members_pay_or: data.members_pay_or,
    members_pay_place: data.members_pay_place,
    members_pay_address: data.members_pay_address,
    members_pay_city: data.members_pay_city,
    members_pay_province: data.members_pay_province,
    members_pay_country: data.members_pay_country,
    members_pay_postal: data.members_pay_postal,
  };

  const yupSchema = Yup.object({
    members_pay_mode: Yup.string().required("Required"),
    members_pay_amount: Yup.string().required("Required"),
    // members_pay_or: Yup.string().required("Required"),
    // members_pay_place: Yup.string().required("Required"),
    members_pay_address: Yup.string().required("Required"),
    members_pay_city: Yup.string().required("Required"),
    members_pay_province: Yup.string().required("Required"),
    members_pay_country: Yup.string().required("Required"),
    members_pay_postal: Yup.string().required("Required"),
  });

  return (
    <>
      <input
        type="radio"
        name="tabs"
        id="tab-payment"
        defaultChecked={getUrlParam().get("tab") === "pay" ? true : false}
      />
      <label htmlFor="tab-payment" className="menu-label">
        PAYMENT INFO
      </label>
      <div className="tab" style={{ padding: "30px 10px" }}>
        <div className="row">
          <div className="col-lg-3 col-md-2 col-sm-12"> </div>
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h4 className="title-box-light">
              <HiLocationMarker />
              <strong>Payment Information</strong>
            </h4>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-12"> </div>
        </div>
        {/* <MembersPayment
          props={props}
          cid={data.branch_code + "-" + data.members_cid}
          progAmount={data.programlist_inst_amount}
          payMode={data.members_pay_mode}
          payAmount={data.members_pay_amount}
        /> */}

        {/* <MembersCashBenefits cid={data.branch_code + "-" + data.members_cid} /> */}

        <Formik
          initialValues={initVal}
          validationSchema={yupSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const member = await fetchApi(
              devApiUrl + "/admin/members/update-members-payment.php",
              values
            );

            // console.log(member);
            if (typeof member === "undefined") {
              console.log("undefined");
              setLoading(false);
            }

            if (!member.status) {
              console.log(member.message);
              setLoading(false);
            }

            if (member.status) {
              console.log("Member updated");
              dispatch(setSuccess(true));
              dispatch(setMessage("Successfuly saved."));
              setLoading(false);
            }
          }}
        >
          {(props) => {
            // console.log(props.isSubmitting);
            if (city) {
              props.values.members_pay_city = city.city_name;
              props.values.members_pay_province = city.city_province;
              props.values.members_pay_postal = city.city_postal;
              props.values.members_pay_country = city.city_country;
            }

            return (
              <Form>
                {/* <ModalAddHospitalization /> */}

                <div className="row  mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      <InputSelect
                        label="Mode of Payment"
                        name="members_pay_mode"
                      >
                        <option value=""> -- </option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Semi Annually">Semi Annually</option>
                        <option value="Annually">Annually</option>
                        <option value="Lump Sum">Lump Sum</option>
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Amount"
                        type="text"
                        name="members_pay_amount"
                        readOnly
                        onKeyPress={handleKey}
                      />
                    </div>

                    <Link
                      to={`/admin/members-view?id=${getUrlParam().get("id")}`}
                      className="btn"
                    >
                      Go to Payment
                    </Link>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                {/* <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <HiLocationMarker />
                      <strong>CASH BENEFITS</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row  mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="content-box">
                      <InputText
                        label="Hospitalization Amount"
                        type="text"
                        name="members_hospital_amount"
                        onKeyPress={handleKey}
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <MyTextArea
                        label="Remarks"
                        type="text"
                        name="members_hospital_remarks"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Medical Reimbursement Amount"
                        type="text"
                        name="members_medreim_amount"
                        onKeyPress={handleKey}
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <MyTextArea
                        label="Remarks"
                        type="text"
                        name="members_medreim_remarks"
                        disabled={props.isSubmitting}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div> */}

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <h4 className="title-box-light">
                      <HiLocationMarker />
                      <strong>Address</strong>
                    </h4>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row mb--30">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    {/* <div className="content-box">
                      <InputSelect
                        label="Mode of Payment"
                        name="members_pay_mode"
                      >
                        <option value=""> -- </option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Semi Annually">Semi Annually</option>
                        <option value="Annually">Annually</option>
                      </InputSelect>
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Amount"
                        type="text"
                        name="members_pay_amount"
                        disabled={props.isSubmitting}
                        onKeyPress={handleKey}
                      />
                    </div> */}

                    <div className="content-box">
                      <InputText
                        label="Place of Collection"
                        type="text"
                        name="members_pay_place"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="House No./Village/Street/Barangay"
                        type="text"
                        name="members_pay_address"
                        disabled={props.isSubmitting}
                      />
                    </div>

                    <MembersSearchCity setCity={setCity} />

                    <div className="content-box">
                      <InputText
                        label="City"
                        type="text"
                        name="members_pay_city"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Province"
                        type="text"
                        name="members_pay_province"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Country"
                        type="text"
                        name="members_pay_country"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Postal Code"
                        type="text"
                        name="members_pay_postal"
                        disabled={props.isSubmitting}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <button
                      className="btn btn-full"
                      id="create-new"
                      type="submit"
                      disabled={props.isSubmitting}
                    >
                      <span>
                        <FaSave />
                      </span>
                      <span>Save {loading && <SpinnerButton />}</span>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-2 col-sm-12"> </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default MembersEditPayment;
