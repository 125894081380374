export const StoreReducer = (state, action) => {
  switch (action.type) {
    case "ERROR":
      return {
        ...state,
        error: action.payload,
      };

    case "MESSAGE":
      return {
        ...state,
        message: action.payload,
      };

    case "SUCCESS":
      return {
        ...state,
        success: action.payload,
      };

    case "SAVE":
      return {
        ...state,
        isSave: action.payload,
      };

    case "VIEW_LEDGER":
      return {
        ...state,
        isViewLedger: action.payload,
      };

    case "RESULT":
      return {
        ...state,
        resultSet: action.payload,
      };

    case "CONFIRM":
      return {
        ...state,
        isConfirm: action.payload,
      };

    case "IS_ADD":
      return {
        ...state,
        isAdd: action.payload,
      };

    case "IS_ADD2":
      return {
        ...state,
        isAdd2: action.payload,
      };

    case "START_INDEX":
      return {
        ...state,
        startIndex: action.payload,
      };

    case "IS_SEARCH":
      return {
        ...state,
        isSearch: action.payload,
      };

    case "STATUS":
      return {
        ...state,
        status: action.payload,
      };

    case "IS_PROGRAM_CHANGE":
      return {
        ...state,
        isProgramChange: action.payload,
      };

    case "IS_CREATE_PASS":
      return {
        ...state,
        isCreatePass: action.payload,
      };

    case "CREDENTIALS":
      return {
        ...state,
        credentials: action.payload,
      };

    case "IS_LOGIN":
      return {
        ...state,
        isLogin: action.payload,
      };

    case "IS_ACCOUNT_SAME":
      return {
        ...state,
        isAccountSame: action.payload,
      };

    case "SEARCH_URL":
      return {
        ...state,
        searchUrl: action.payload,
      };

    default:
      return state;
  }
};
