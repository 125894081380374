import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { GrFormClose } from "react-icons/gr";
import { StoreContext } from "../../../../store/StoreContext";
import { InputText } from "../../../helpers/FormInputs";
import SpinnerButton from "../../../widget/SpinnerButton";
import { setIsAdd } from "../../../../store/StoreAction";
import { fetchData } from "../../../helpers/fetchData";

const ModalAddBranch = ({ edit }) => {
  const { store, dispatch } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);
  const { itemEdit, setItemEdit } = edit;

  const initVal = {
    branch_aid: itemEdit ? itemEdit.branch_aid : "",
    branch_code: itemEdit ? itemEdit.branch_code : "",
    branch_city: itemEdit ? itemEdit.branch_city : "",
    branch_province: itemEdit ? itemEdit.branch_province : "",
    branch_region: itemEdit ? itemEdit.branch_region : "",
  };

  const yupSchema = Yup.object({
    branch_code: Yup.string().required("Required"),
    branch_city: Yup.string().required("Required"),
    branch_province: Yup.string().required("Required"),
    branch_region: Yup.string().required("Required"),
  });

  const handleClose = () => {
    dispatch(setIsAdd(false));
    setItemEdit(false);
  };

  return (
    <>
      <div className="modal modal-add-employee">
        <div className="display-center">
          <div className="title-container t-exbold notify-info">
            <h4 className="modal-title">
              <span className="color--blue">
                {itemEdit ? "Edit Branch" : "Add Branch"}
              </span>
            </h4>
            <span className="btn--close" onClick={handleClose}>
              <GrFormClose />
            </span>
          </div>
          <div className="bg--white">
            <Formik
              initialValues={initVal}
              validationSchema={yupSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                fetchData(
                  setLoading,
                  itemEdit
                    ? "/admin/branch/update-branch.php"
                    : "/admin/branch/create-branch.php",
                  values, // form data values
                  null, // result set data
                  "", // success msg
                  "" + values.branch_code + " - Branch code is already used.", // additional error msg if needed
                  dispatch, // context api action
                  store, // context api state
                  false, // boolean to show success modal
                  false // boolean to show load more functionality button
                );
                setItemEdit(false);
              }}
            >
              {(props) => {
                // console.log(props.isSubmitting);

                return (
                  <Form>
                    <div className="content-box">
                      <InputText
                        label="Branch Code"
                        type="text"
                        name="branch_code"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="City"
                        type="text"
                        name="branch_city"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Province"
                        type="text"
                        name="branch_province"
                        disabled={loading}
                      />
                    </div>

                    <div className="content-box">
                      <InputText
                        label="Region"
                        type="text"
                        name="branch_region"
                        disabled={loading}
                      />
                    </div>

                    <div className="button-container">
                      <button type="submit" className="btn" disabled={loading}>
                        {itemEdit ? "SAVE" : "ADD"}{" "}
                        {loading && <SpinnerButton />}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAddBranch;
